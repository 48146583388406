import React, {useState, useEffect} from 'react';
import { useCookies } from 'react-cookie';
import { Link } from 'react-router-dom';
import moment from "moment";
import Icon from '@mdi/react';
import { mdiDownload, mdiCreditCard} from '@mdi/js';


import settings from '../Settings';
import { fetchProtectedData, fetchBlobProtectedData } from '../Requests';

import LayoutLogged from '../Components/LayoutLogged';
import Placeholder from '../Components/Placeholder';


export default function IstoricFacturi() {

  const [, setError] = useState(null);
	const [cookie] = useCookies([settings.auth.cookie_token]);
	const [loading, setLoading] = useState(0);
  const [protectedContent, setProtectedContent] = useState({
    rowFacturi: []
  });

	const download_factura = (idFactura) => {

		setLoading(idFactura);
    let url = settings.url.contul_meu.download_factura;
    //  console.log("vrei sa descarci?", url);
		fetchBlobProtectedData(
      url,
      cookie,
      settings.miscellaneous.download_factura,
      {id: idFactura},
      {setLoading}
    );

  }

	const plateste_online = (jwt) => {
		document.location = settings.url.contul_meu.plateste_factura + jwt
		//	alert("Plata online, cu cardul, nu este încă disponibilă. Vă rugăm să reveniți în curând!" + jwt);
	}

	useEffect(() => {
    setProtectedContent({
      rowFacturi: [
        {}
      ]
    });

    //	fetchDataIstoricFacturi(cookie, setProtectedContent, setError)
		fetchProtectedData(
			settings.url.contul_meu.istoric_facturi, 
			cookie, 
			{}, 
			{setProtectedContent, setError}
		)

	}, [cookie])  //  array gol, ruleaza o singura data. ruleaza de fiecare data cand este modificat ceva in [] 
	
	return (
		<LayoutLogged>
			<div className="card">
				<div className="card-header">
					<h4 className="card-title">Facturile mele</h4>
				</div>
				<div className="card-body pt-0">
					<div className="transaction-table">
						<div className="table-responsive">
							<table className="table table-striped">
								<thead>
									<tr>
										<th className="text-center col-1">Descarcă</th>
										<th className="text-center col-2">Dată factură</th>
										<th className="text-center col-2">Dată scadență</th>
										<th className="text-center col-2">Număr factură</th>
										<th className="text-center col-2">Valoare lei, cu TVA</th>
										<th className="text-center col-2">Rest plata</th>
										<th className="text-center col-1">Platește</th>
									</tr>
								</thead>
								<tbody>
									{
										protectedContent.rowFacturi.map((value, index)=>{
											return(
												<tr key={index} className="border">
													<td className='text-center'>
														{value.id_factura 
														? 
															<Link
																
																onClick={(e)=>{
																	download_factura(value.id_factura);
																}}
															>
																{
																	(loading === value.id_factura 
																		? 
																		<span className="sold-thumb m-0 ms-4 spinner-border" title="În curs de descărcare"></span>	
																		: 
																		<span className="sold-thumb m-0 ms-4" title="Descarcă factura">
																			<Icon path={mdiDownload} size={1} />
																		</span>	
																	)
																}
															</Link>
														: <Placeholder width="3" />
														}
													</td>
													<td className="fw-normal text-center">{value.data_factura ? moment(value.data_factura).format("DD/MM/YYYY") : <Placeholder />}</td>
													<td className="fw-normal text-center">{value.data_scadenta ? moment(value.data_scadenta).format("DD/MM/YYYY") : <Placeholder />}</td>
													<td className="fw-normal text-center">{value.id_factura ? value.serie_factura + value.numar_factura : <Placeholder />}</td>
													<td className="fw-normal text-end">{value.total_lei ? value.total_lei : <Placeholder />}</td>
													<td className="fw-normal text-end">{value.rest_incasare ? value.rest_incasare : <Placeholder />}</td>
													<td className="text-center">{value.id_factura ? ((value.rest_incasare > 0 && value.order_id == '') ? <Link to="#" onClick={() => plateste_online(value.jwt)} className='buy-thumb m-0 ms-3' title="Platește factura"><Icon path={mdiCreditCard} size={1} /></Link> : null) : <Placeholder width="3" />}</td>
												</tr>
											)
										})
									}
								</tbody>
							</table>
						</div>
					</div>
				</div>
      </div>
		</LayoutLogged>
	)
}