import React from "react";

import LayoutLoggedUnLogged from "../Components/LayoutLoggedUnLogged";

function TermeniConditii() {
  return (
  <LayoutLoggedUnLogged>
    <div className="card">
      <div className="card-header">
          <h4 className="card-title">Termeni si conditii</h4>
      </div>
      <div className="card-body pt-4">
        <p><strong>Termeni de utilizare</strong><br />Acest website este oferit de LUXTEN LIGHTING COMPANY S.A., cu sediul social in municipiul Bucuresti, strada Parangului, nr. 76, Sector 1, cod postal 12328, telefon 0216688838, fax 0216688823, inmatriculata la Registrul Comertului sub nr. J40/9082/2009, Cod Unic de Inregistrare RO6734030.<br />Accesul si utilizarea website-ului se fac sub autoritatea regulilor si conditiilor mentionate mai jos. Utilizarea website-ului presupune acceptarea unor termeni si conditii si asumarea responsabilitatii in cazul incalcarii lor.<br />Termenii si conditiile pot fi modificate in orice moment de administratorul website-ului, fara notificarea prealabila a utilizatorilor. Utilizatorii vor avea acces permanent la termenii si conditiile de utilizare.<br />Drepturile de autor asupra intregului continut al site-ului apartin in totalitate LUXTEN LIGHTING COMPANY S.A. si copierea si utilizarea acestora se pot realiza doar ulterior obtinerii unui acord scris din partea proprietarului.</p>
        <p><strong>Reguli de utilizare a website-ului</strong><br />Utilizatorii se angajeaza: <br /> • sa respecte legile in vigoare si sa nu desfasoare activitati de tip hacking sau altele similare, pentru a nu afecta functionarea website-ului sau securitatea informatiilor;<br /> • sa nu modifice, copieze, transmita, afiseze, publice sau reproduca informatiile de pe acest site si sa nu creeze produse similare sau sa vanda informatii sau servicii obtinute prin intermediul website-ului.<br />Utilizatorii au urmatoarele drepturi:<br /> • de a explora continutului website-ului www.luxten.com;<br /> • de a reproducere sau de a utiliza informatiile prezentate pe website, numai cu acordul prealabil al proprietarului.</p>
        <p><br /><strong>Limitarea raspunderii</strong><br />Toate informatiile, imaginile si specificatiile tehnice oferite pe acest website au caracter informativ, putand fi schimbate in orice moment, fara o instiintare prealabila si fara a constitui o obligativitate contractuala.</p>
        <p><strong>Drepturi de autor</strong> <br />Intregul continut al website-ului www.luxten.com este proprietatea LUXTEN LIGHTING COMPANY S.A. si este protejat conform Legii nr. 8/1996 privind drepturilor de autor si drepturilor conexe.<br />Sunt interzise: <br /> • copierea, distributia, transmiterea, publicarea, reproducerea sau crearea unui document prin utilizarea informatiile sau serviciilor prezentate pe website;<br /> • transmiterea informatiilor prezentate sau obtinute prin intermediul website-ului catre persoane/ departamente din cadrul firmelor concurente.<br />Utilizarea fara acordul scris al LUXTEN LIGHTING COMPANY S.A. a elementelor enumerate mai sus se pedepseste conform legilor in vigoare.</p>
        <p><strong>Date cu caracter personal</strong><br />LUXTEN LIGHTING COMPANY S.A. se obliga sa administreze in conditii de siguranta datele furnizate de untilizatori in conformitate cu Legea nr. 677/2001 privind prelucrarea datelor cu character personal si libera circulatie a acestora, cu modificarile si completarile ulterioare si cu Legea nr. 235/2015 pentru modificarea si completarea Legii nr. 506/2004 privind prelucrarea datelor cu caracter personal si protectia vietii private in sectorul comunicatiilor electronice.<br />Legislatie<br />Termenii si conditiile de utilizare a website-ului se supun legislatiei Romaniei. In caz de litigiu, se va incerca solutionarea amiabila intre parti. In cazul in care nu se ajunge la o intelegere amiabila, se va apela la instantele judecatoresti competente.</p>
        <p><strong>Ștergerea datelor personale</strong><br />Dacă nu mai doriți să aveți cont pe my.luxten.com iar toate datele dvs. sa fie sterse, vă rugam să vă adresați Luxten Lighting Company, la adresa de email telegestiune@luxten.com.</p>
      </div>
    </div>
  </LayoutLoggedUnLogged>
  )
}


export default TermeniConditii