import React from "react"

import { Submit } from "../FormInputs"
export default function ChangePasswordForm(props) {
  return (
    <div className="card">
      <div className="card-header">
          <h4 className="card-title">Schimbare parolă</h4>
      </div>
      <div className="card-body pt-0">
        <form
          method="post"
          className="mt-3"
          onSubmit={props.handleSubmit}
        >
          <div className="mb-3">
              <label>Parolă</label>
              {props.error !== null ? <div className="mb-3 text-danger">{props.error.password}</div> : null}
              <input
                type="password"
                placeholder="Introdu o parolă puternică"
                name="password"
                className={props.error?.password !== undefined ? "border-danger form-control" : "form-control"}
                onChange={(event) => props.setPassword(event.target.value)}
              />
          </div>
          <div className="mb-3">
              <label>Reintrodu parola</label>
              {props.error !== null ? <div className="mb-3 text-danger">{props.error.checkPassword}</div> : null}
              <input
                type="password"
                placeholder="Reintrodu parola"
                name="checkPassword"
                className={props.error?.checkPassword !== undefined ? "border-danger form-control" : "form-control"}
                onChange={(event) => props.setCheckPassword(event.target.value)}
              />
          </div>
          <div className="text-center mt-4">
            <Submit loading={props.loading} title="Schimbă parola" />
          </div>
        </form>
      </div>
    </div>
  )
}