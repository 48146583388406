import React from "react";
import { Link } from "react-router-dom";

import HeaderUnLogged from "../HeaderUnLogged";

import { Submit } from "../FormInputs"

export default function ResetPasswordForm(props) {
  return (
    <>
      <HeaderUnLogged />
      <div className="authincation section-padding">
        <div className="container h-100">
          <div className="row justify-content-center h-100 align-items-center">
            <div className="col-xl-5 col-md-6 mt-5">
              <div className="auth-form card">
                  <div className="card-header justify-content-center">
                    <h4 className="card-title">Creare cont</h4>
                  </div>
                  <div className="card-body">
                      <form
                        method="post"
                        className="signup_validate"
                        onSubmit={props.handleSubmit}
                      >
                      <div className="mb-3">
                          <label>Parolă</label>
                          {props.error !== null ? <div className="mb-3 text-danger">{props.error.password}</div> : null}
                          <input
                            type="password"
                            placeholder="Introdu o parolă puternică"
                            name="password"
                            className={props.error?.password !== undefined ? "border-danger form-control" : "form-control"}
                            onChange={(event) => props.setPassword(event.target.value)}
                          />
                      </div>
                      <div className="mb-3">
                          <label>Reintrodu parola</label>
                          {props.error !== null ? <div className="mb-3 text-danger">{props.error.checkPassword}</div> : null}
                          <input
                            type="password"
                            placeholder="Reintrodu parola"
                            name="checkPassword"
                            className={props.error?.checkPassword !== undefined ? "border-danger form-control" : "form-control"}
                            onChange={(event) => props.setCheckPassword(event.target.value)}
                          />
                      </div>
                      <div className="text-center mt-4">
                        <Submit loading={props.loading} title="Resetare parolă" />
                      </div>
                  </form>
                  <div className="new-account mt-3">
                    <p>
                      Ai deja cont?{" "}
                      <Link
                        className="text-primary"
                        to={"/"}
                      >
                        Autentificare
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}