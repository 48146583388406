import {useState } from 'react';

export default function Placeholder(props) {
  const [width] = useState(props.width || 10);

  return (
    <span className="placeholder-glow"  style={{display:'inline-block', minWidth: width*10, minHeight: '10px'}}>
      <span className="placeholder w-100 h-100"></span>
    </span>
  )
}