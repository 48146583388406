import React, {useState, useEffect} from 'react';
//import { useCookies } from 'react-cookie';
import { useParams } from 'react-router-dom';
//import moment from "moment";
//import Icon from '@mdi/react';
//import { mdiDownload, mdiCreditCard} from '@mdi/js';


import settings from '../Settings';
import { fetchData, fetchProtectedData, fetchBlobProtectedData } from '../Requests';

import LayoutLoggedUnLogged from "../Components/LayoutLoggedUnLogged";
import Placeholder from '../Components/Placeholder';


export default function RaspunsPlata() {
  const { orderHash } = useParams();

  const [error, setError] = useState(null);

  const [responseOk, setResponseOk] = useState({});
	//const [cookie] = useCookies([settings.auth.cookie_token]);
	const [loading, setLoading] = useState(0);
  //  const [protectedContent, setProtectedContent] = useState({});


	// useEffect(() => {
  //   //	fetchDataIstoricFacturi(cookie, setProtectedContent, setError)
	// 	fetchProtectedData(
	// 		settings.url.contul_meu.get_raspuns_plata + orderHash, 
	// 		cookie, 
	// 		{}, 
	// 		{setProtectedContent, setError}
	// 	)

	// }, [cookie])  //  array gol, ruleaza o singura data. ruleaza de fiecare data cand este modificat ceva in [] 
	
  useEffect(() => {
    fetchData(settings.url.contul_meu.get_raspuns_plata,
      {orderHash: orderHash}, 
      {setError, setResponseOk, setLoading}
    );
  }, [orderHash])  

  console.log(error, responseOk);

  const renderContent = () => {
    switch(true) {
      case (Object.keys(responseOk).length > 0 && responseOk.response_action === '0'):
        return (
          <>
            <h4 className="card-title text-success pb-2">
              Va multumim! Plata a fost efectuata cu succes!
            </h4>
            <p>Mesajul bancii este: {responseOk.response_message}</p>
          </>
        );
      case (Object.keys(responseOk).length > 0 && responseOk.response_action !== '0'):
        return (
          <>
            <h4 className="card-title text-danger pb-2">
              Plata nu a fost procesata!
            </h4>
            <p>Mesajul bancii este: {responseOk.response_message}</p>
          </>
        )
      case (error != null): 
        return (
          <h4 className="card-title text-danger">{error}</h4>
        );
      default: 
        return (
          <Placeholder width={60} />
        );
    }
  }

  return(
    <LayoutLoggedUnLogged>
    <div className="card">
      <div className="card-header">
        <h4 className="card-title">Plata factura</h4>
      </div>
      <div className="card-body pt-4">
        {renderContent()}
      </div>
    </div>
  </LayoutLoggedUnLogged>
  )
  
	// return (
	// 	<LayoutLogged>
	// 		<div className="card">
	// 			<div className="card-header">
	// 				<h3 className="card-title">Plata factura</h3>
	// 			</div>
	// 			<div className="card-body py-5">
  //         {renderContent()}
	// 			</div>
  //     </div>
	// 	</LayoutLogged>
	// )
}