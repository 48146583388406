import React, {useState, useEffect} from 'react';
import { useCookies } from 'react-cookie';

import InformatiiContract from '../Components/IstoricIndecsi/InformatiiContract';
import PunctConsum from '../Components/IstoricIndecsi/PunctConsum';
import AreaChart from "../Components/IstoricIndecsi/GraficCitiri";
import UltimeleCitiri from '../Components/IstoricIndecsi/UltimeleCitiri';

import settings from '../Settings';
import { fetchProtectedData, fetchBlobProtectedData } from '../Requests';

import LayoutLogged from '../Components/LayoutLogged';

export default function IstoricCitiriContoare() {
  const [, setError] = useState(null);
	const [cookie] = useCookies([settings.auth.cookie_token]);
  const [protectedContent, setProtectedContent] = useState({
    contracte: [
      {}
    ],
    citiri_graph: [
      [
        {}
      ]
    ],
    citiri: [
      [
        {}
      ]
    ]
  });

	useEffect(() => {

    fetchProtectedData(
      settings.url.contul_meu.istoric_citiri,
      cookie, 
      {},
      {setProtectedContent, setError}
    )

	}, [cookie])  //  array gol, ruleaza o singura data. ruleaza de fiecare data cand este modificat ceva in [] 

  function download_excel(idContract) {
    let url = settings.url.contul_meu.download_istoric_citiri;
    //  console.log("vrei sa descarci?", url);
    fetchBlobProtectedData(
      url,
      cookie,
      settings.miscellaneous.istoric_consum_download_file,
      {idContract},
      {}
    );

  }
  
	return (
		<LayoutLogged>

    <div className="container">
      {
        Object.keys(protectedContent?.contracte).map((key)=>{

          //  console.log("rand", protectedContent.contracte[key]);
          return(
            <div key={key}  className="row">
              <div className="col-xl-12">
                <div className="row">
                  <div className="col-xl-6 col-lg-6 col-xxl-6">
                    <InformatiiContract info={protectedContent.contracte[key]} />
                  </div>
                  <div className="col-xl-6 col-lg-6 col-xxl-6">
                    <PunctConsum info={protectedContent.contracte[key]} />
                  </div>
                </div>
              </div>
              <div className="col-xl-12">
                <AreaChart dataSeries={protectedContent.citiri_graph[key]} />
              </div>
              <div className="col-xl-12">
                <UltimeleCitiri info={protectedContent.citiri[key]} download_excel={download_excel} keyContract={key} />
              </div>
            </div>
          )
        })
      }
    </div>
           
      
		</LayoutLogged>
	)
}