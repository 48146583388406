import moment from "moment";

import Placeholder from "../Placeholder";

export default function UltimeleCitiri(props) {
  return (
    <div className="card">
      <div className="card-header">
        <h4 className="card-title">Ultimele citiri</h4>
        <button className="text-right btn btn-info p-2" 
        onClick={()=>{
          props.download_excel(props.keyContract);
        }}>Descarcă fisier Excel</button>
      </div>
      <div className="card-body pt-0">
        <div className="transaction-table">
          <div className="table-responsive">
            <table className="table table-striped">
              <thead>
                <tr>
                <th className="text-center col-2">Serie contor</th>
                  <th className="text-center col-2">Dată citire</th>
                  <th className="text-center col-1">Index anterior</th>
                  <th className="text-center col-1">Index curent</th>
                  <th className="text-center col-1">Consum (kWh)</th>
                  <th className="text-center col-4">Perioadă facturare</th>
                </tr>
              </thead>
              <tbody>
                {
                  props.info.map((valueCitiri, keyCitiri)=>{
                    return(
                      <tr key={`${props.keyContract}--${keyCitiri}`}>
                        <td className="text-center">{valueCitiri.serie !== undefined ? valueCitiri.serie : <Placeholder width={5} />}</td>
                        <td className="text-center">{valueCitiri.data_citire !== undefined ? moment(valueCitiri.data_citire).format("DD/MM/YYYY") : <Placeholder width={5} />}</td>
                        <td className="text-end">{valueCitiri.index_anterior !== undefined ? valueCitiri.index_anterior : <Placeholder width={5} />}</td>
                        <td className="text-end">{valueCitiri.index_curent !== undefined ? valueCitiri.index_curent : <Placeholder width={5} />}</td>
                        <td className="text-end">{valueCitiri.consum_luna_kwh !== undefined ? valueCitiri.consum_luna_kwh : <Placeholder width={5} />}</td>
                        <td className="text-center">{valueCitiri.perioada_facturare !== undefined ? valueCitiri.perioada_facturare : <Placeholder width={5} />}</td> 
                      </tr>
                    )
                  })
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}