import React from "react";

import LayoutLoggedUnLogged from "../Components/LayoutLoggedUnLogged";

function DespreCookie() {
  return (
  <LayoutLoggedUnLogged>
    <div className="card">
      <div className="card-header">
        <h4 className="card-title">Despre cookie-uri</h4>
      </div>
      <div className="card-body pt-4">
        <p>
          Îți mulțumim că ai ales să ne vizitezi site-ul! Acest site este operat de către Luxten Lighting Company S.A. 
          Website-ul luxten.com folosește cookie-uri pentru scopuri legate de îmbunătățirea și personalizarea experienței de navigare potrivit preferințelor utilizatorilor, reținerea informațiilor de autentificare pentru a nu fi necesară înregistrarea la fiecare vizită, protejarea securității sistemelor utilizatorilor și ale noastre ori realizarea de statistici. 
          De exemplu, unele cookie-uri ne oferă informații importante cu privire la modul în care vizitatorii folosesc site-ul nostru, care ne permit apoi să îl facem mai adecvat nevoilor lor, iar alte cookie-uri ne permit să vedem câți utilizatori accesează site-ul nostru într-o anumită perioadă. 
          Cookie-urile utilizate de către prezentul site oferă un feedback valoros și ne ajută în încercarea de a îmbunătăți exepriența online. 
          Mai jos îți oferim detalii cu privire la cookie-urile de pe acest site si la alegerile pe care le ai cu privire la activarea sau dezactivarea lor. 
          Totuși, te rugăm să ții minte că, la fel ca toate site-urile, website-ul nostru se bazează pe această tehnologie pentru a putea funcționa în condiții optime, astfel încât dacă refuzi să permiți funcționarea ei, atunci calitatea experienței tale de navigare ar putea suferi.
        </p>
        <p><strong>Ce este un cookie?</strong></p>
        <p>
          Unele persoane se referă la cookie-uri drept Internet cookies, browser cookies sau HTTP cookies. Acestea sunt mai multe denumiri pentru una și aceeași tehnologie, denumită popular cookie. Un cookie este un fragment de text (litere și numere) de mici dimensiuni, care va fi stocat pe computerul, dispozitivele mobile sau alte echipamente ale unui utilizator de pe care se accesează un site Internet. 
          Cookie-urile sunt formate din două părți: numele și conținutul (sau valoarea) lor.
        </p>
        <p><strong>La ce folosesc cookie-urile?</strong></p>
        <p>Cu ajutorul cookie-urilor, site-urile rețin informații despre vizita utilizatorilor, cum ar fi: limba preferată, numele de utilizator și parola (pentru a nu fi necesară reînregistrarea la fiecare vizită pe site), tipul de dispozitiv folosit (computer, telefon mobil ș.a.) pentru a accesa site-ul. Cookie-urile permit simplificarea experienței de navigare pe un site, iar proprietarii site-urilor au posibilitatea de a oferi informații mai relevante utilizatorilor, conform nevoilor lor. Cookie-urile ajută la funcționarea eficientă a Internetului și au ca rezultat navigarea prietenoasă şi personalizată fiecărui utilizator. Fără ele, site-urile de pe Internet ar fi mult mai neplăcute sau plictisitoare. Mai mult, cookie-urile ajută proprietarii de site-uri să le dezvolte continuu. Cookie-urile pot obține informaţii valoroase asupra modului cum este utilizat website-ul de către vizitatori, astfel încât să se poată furniza acestora experienţe din ce în ce mai bune în navigare.</p>
        <p><strong>Ce tipuri principale de cookie-uri exista?</strong></p>
        <p>În funcție de modul de ștergere, există cookie-uri care sunt șterse imediat ce închizi browser-ul de Internet (acestea sunt denumite și cookie-uri de sesiune) și cookie-uri care sunt stocate pe dispozitivul tău cu scopul de a fi folosite inclusiv pentru următoarele tale vizite pe site (acestea se mai numesc și cookie-uri persistente deși, în ciuda numelui, expiră după o perioadă de timp). Cookie-urile persistente se reactivează atunci când vizitezi din nou site-ul care le-a plasat pe dispozitivul tău. În funcție de persoana care le plasează pe dispozitivul tău, există cookie-uri proprii (pe care noi le plasăm pe dispozitivul tău) sau cookie-uri plasate de alte persoane (acestea se mai numesc și cookie-uri de la terți).</p>
        <p><strong>Ce durată de viață are un cookie?</strong></p>
        <p>Durata de viață a cookie-urilor depinde foarte mult de la un caz la altul, în funcție de scopul fiecărui cookie. După cum am indicat mai sus, unele cookies sunt folosite doar pentru o singură sesiune de navigare, în timp ce altele persistă pentru o perioadă de timp determinată după ce părăsești site-ul. Independent de tipul de cookies, poți oricând să le ștergi sau să le dezactivezi.</p>
        <p><strong>Sunt cookie-urile periculoase pentru dispozitivul meu?</strong></p>
        <p>Cookie-urile sunt inofensive. Nu conțin programe, viruși sau alte fișiere dăunătoare și nu pot accesa informațiile de pe dispozitivul utilizatorului. Deoarece cookie-urile sunt instalate prin solicitarea unui browser Internet (a webserver-ului acestuia), doar acel webserver care le-a trimis poate să le acceseze din nou atunci când utilizatorul revine pe site-ul asociat acelui webserver. Totuşi, cookie-urile pot fi folosite în scopuri negative, ca o formă de spyware. Multe produse anti-spyware marchează însă cookie-urile pentru a fi șterse în cadrul procedurilor de ştergere/scanare anti-virus/anti-spyware. În general, browser-ele au setări de confidenţialitate care furnizează diferite nivele de acceptare a cookie-urilor, perioada de valabilitate şi ştergere automată după ce utilizatorul a vizitat un website.</p>

        <p><strong>Au cookie-urile vreo legătură cu datele mele cu caracter personal?</strong></p>
        <p>Unele cookie-uri colectează informații anonime, care nu spun nimic despre tine. Altele colectează informații care te pot identifica, de exemplu, adresa IP.</p>
        <p><strong>Care sunt scopurile concrete pentru care prezentul site foloseste cookie-uri?</strong></p>
        <p>
          Cookie-urile de pe acest site sunt folosite pentru mai multe scopuri specifice. De exemplu:
        <ul>
          <li>pentru a îți permite să te autentifici și, în acest fel, să ai acces la informațiile tale personalizate;</li>
          <li>pentru a îmbunătăți site-ul și pentru a realiza mentenanța acestuia;</li>
          <li>pentru dezvoltarea caracteristicilor de siguranță ale site-ului;</li>
          <li>pentru a putea ține cont de preferințele pe care le-ai exprimat în sesiuni de navigare anterioare, personalizând experiența ta pe website;</li>
          <li>pentru a soluționa problemele pe care le-ai putea întâmpina la accesare;</li>
          <li>pentru publicitate;</li>
          <li>pentru efectuarea analizei datelor și monitorizarea tendințelor de utilizare.</li>
        </ul>
        </p>
        <p><strong>Cookie-urile noastre</strong></p>
        <p>În multe cazuri, cookie-urile nu preiau informații asociate unei persoane pe care am identificat-o sau pe care o putem identifica. Aceasta înseamnă că acele cookie-uri nu vor reține datele tale cu caracter personal, așa încât vei rămâne anomim(ă).</p>
        <p><strong>Cookie-uri de la terți</strong></p>
        <p>Există situații în care anumite părți din alte site-uri pot apărea pe site-ul nostru. Gândește-te, de exemplu, la un buton Like al Facebook sau la un video de pe Youtube (acestea sunt doar exemple) - în ziua de astăzi, aceste opțiuni sunt prezente pe aproape orice site. Atunci când aceste elemente apar pe site-ul nostru, înseamnă că le-am permis companiilor care dețin platformele respective să plaseze cookie-uri pe el. Permitem unele cookie-uri de la terți pe site-ul nostru, dar nu permitem aceasta oricui. Cookie-urile de la terți sunt folosite în primul rând pentru a îți oferi o experiență mai utilă, pentru a realiza statistici despre numărul de vizitatori și modul în care aceștia folosesc site-ul nostru. De exemplu, butoanele de like sau share de pe website-uri pot permite rețelelor de socializare să plaseze cookie-uri pe website. Nu controlăm aceste cookie-uri de la terți și nici informațiile pe care le-ar putea colecta, așa încât te rugăm să consulți politica acelor furnizori dacă vrei să afli mai multe informații. Deoarece este vorba despre site-uri operate de alte persoane, cookie-urile care permit plasarea conținutului lor pe site-ul nostru provin de la operatorii lor, și nu de la noi. Este posibil ca terții să folosească cookie-uri pentru propriile scopuri. Pentru informații despre aceste cookie-uri de la terți, te rugăm să consulți politicile lor privind cookie-urile.</p>
        <p>Google:</p>
        <p>Ca exemplu, cookie-urile Google creează statistici privind utilizarea site-ului; ajută la autentificarea utilizatorilor, prevenind utilizarea frauduloasă a datelor de autentificare și protejează datele utilizatorilor de accesul neautorizat; ajută Google să rețină (printr-un ID unic acordat de Google) preferințele tale și alte informații, cum ar fi limba preferată de afișare a site-ului (de exemplu, româna); ajută Google să ofere reclame adaptate preferintelor.</p>
        <p>Google Analytics:</p>
        <p>Google Analytics este un instrument web de analizare care urmărește și raportează modul în care site-ul este folosit, ajutându-ne să îl îmbunătățim. Cookie-urile Google Anaylitcs colectează informații privind numărul de vizitatori ai site-ului, paginile vizitate, timpul petrecut pe site. Aceste informații sunt colectate în formă anonimă (agregată), fără referire la identitatea ta. Aceste cookie-uri ne oferă informații anonime despre ce faci pe site-ul nostru și cum ai ajuns la el. Ele ne permit, de asemenea, să cunoaștem problemele pe care vizitatorii le-ar putea întâmpina atunci când încearcă să acceseze site-ul nostru.</p>
        <p>Youtube:</p>
        <p>Dacă pe site-ul nostru sunt postate clipuri video prin intermediul Youtube, Youtube își va plasa cookie-urile pe dispozitivul tău atunci când ai apăsat Play. Cookie-urile Youtube de pe site-ul nostru înregistrează informații statistice anonime - spre exemplu, cu privire la numărul de vizualizări ale unui clip video și setările utilizate pentru vizualizarea acestuia; rețin preferințelor tale pentru a putea să îți afișeze conținut relevant celui vizualizat anterior sau reclame în cadrul unui clip Youtube plasat pe site-ul nostru Cookie-uri utilizate pe site-ul https://my.luxten.com Cookie-uri functionale: Cookie-urile funcţionale stochează preferinţele utilizatorului privind utilizarea cookie-urilor. Acestea nu colectează informaţii care pot identifica utilizatorii, toate datele fiind anonime.</p>

      </div>
    </div>
  </LayoutLoggedUnLogged>
  )
}

export default DespreCookie