import React, {useEffect} from "react"
import { useCookies } from "react-cookie"
import { useState } from "react"

import settings from "../Settings"
import { fetchProtectedData } from "../Requests"

import Placeholder from "../Components/Placeholder"

import LayoutLogged from "../Components/LayoutLogged"

import TransmitereIndexContract from "../Components/TransmitereIndex/TransmitereIndexContract"

export default function TransmitereIndex() {
  // pentru autentificare
  const [cookie] = useCookies([settings.auth.cookie_token]);

  // pentru lista de contracte
  const [, setError] = useState(null);
  const [protectedContent, setProtectedContent] = useState({})

  const [idContract, setIdContract] = useState(0);

	useEffect(() => {
    fetchProtectedData(
			settings.url.contul_meu.contracte, 
			cookie, 
			{}, 
			{setProtectedContent, setError}
		)

	}, [cookie]) 

  //  partea interesanta este ca pot folosi useEffect multiplu, de fiecare data cu alt array
  useEffect(() => {
    if (Object.keys(protectedContent).length > 0) {
      const keys = Object.keys(protectedContent?.contracte);
      setIdContract(protectedContent.contracte[keys[0]].id);
    }
    //  console.log("am preluat", idContract);
	}, [protectedContent, idContract]) 


/*
    
    */

  return (
    <LayoutLogged>
      <div className="card">
        <div className="card-header">
          <h4 className="card-title">Transmitere index autocitit</h4>
        </div>
        <div className="card-body">
          <div className="transaction-widget">
          <p className="mb-3">
            Poți adăuga mai multe locuri de consum în același cont, folosind datele de pe factura noului loc de consum. 
          </p>
          <p className="mb-3">
            Astfel, poți avea acces pe același cont My.luxten.com la mai multe locuri de consum. Din această secțiune poți seta care loc de consum este activ la un moment dat.
          </p>
          <p className="mb-3">Atenție: primul loc asociat odată cu crearea contului, nu poate fi șters!</p>
            {
              Object.keys(protectedContent).length > 0 
              ? 
                <select name="idContract" className="form-control" onChange={(event) => setIdContract(event.target.value)} >
                  {
                    Object.keys(protectedContent?.contracte).map((key)=>{
                      return(
                        <option key={key} value={protectedContent.contracte[key].id}>
                          {
                            `Contor ` + protectedContent.contracte[key].serie_contor + 
                            ` - ` + protectedContent.contracte[key].strada_consum + 
                            ` ` + protectedContent.contracte[key].nr_strada_consum + 
                            ` ` + protectedContent.contracte[key].bloc_consum + 
                            ` ` + protectedContent.contracte[key].apt_consum + ` ` + 
                            ` ` + ((protectedContent.contracte[key].oras_consum !== null) ? protectedContent.contracte[key].oras_consum : '') + ` ` + 
                            ` ` + ((protectedContent.contracte[key].judet_consum !== null) ? protectedContent.contracte[key].judet_consum : '') + ` `
                          }
                        </option>
                      )
                    })
                  }
                </select>
              : <Placeholder width={30} />
            }
            <div className="mt-5 mb-4">
              <TransmitereIndexContract idContract={idContract} />
            </div>
          </div>
        </div>
      </div>      
    </LayoutLogged>
    
  )
}



