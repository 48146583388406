import React, { useState, useEffect } from "react";
import { useCookies } from 'react-cookie';

import settings from "../Settings";
import { fetchProtectedData } from "../Requests";
//  import Placeholder from "../../Components/Placeholder";

import LayoutLogged from '../Components/LayoutLogged';
import InformatiiClient from "../Components/ContulMeu/InformatiiClient";
import AdresaFacturare from "../Components/ContulMeu/AdresaFacturare";
import PuncteConsum from "../Components/ContulMeu/PuncteConsum";
import FacturaElectronica from "../Components/ContulMeu/FacturaElectronica";
import SoldContabil from "../Components/ContulMeu/SoldContabil";



export default function ContulMeu() {

  const [, setError] = useState(null);
	const [cookie] = useCookies([settings.auth.cookie_token]);
  const [protectedContent, setProtectedContent] = useState({
    rowCompanie: {},
    adresaFacturare: {},
    adreseConsum: [],
    sold: {}
  });

	useEffect(() => {
    /*
    setProtectedContent({
      rowCompanie: {}
    });
    */

    fetchProtectedData(
			settings.url.contul_meu.index, 
			cookie, 
			{}, 
			{setProtectedContent, setError}
		)

	}, [cookie])  //  array gol, ruleaza o singura data. ruleaza de fiecare data cand este modificat ceva in []

  return (
    <LayoutLogged>
      <div className="row">
        <div className="col-xl-12">
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-xxl-6">
              <InformatiiClient info={protectedContent.rowCompanie} />
            </div>
            <div className="col-xl-6 col-lg-6 col-xxl-6">
              <div className="row">
                <div className="col-xl-12">
                  <SoldContabil info={protectedContent.sold} />
                </div>
              </div>
              <div className="row">
                <div className="col-xl-12">
                  <AdresaFacturare info={protectedContent.adresaFacturare} />
                </div>
              </div>
              <div className="row">
                <div className="col-xl-12">
                  <FacturaElectronica />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-12">
          <PuncteConsum info={protectedContent.adreseConsum}  />
        </div>
      </div>

    </LayoutLogged>
  )

  /*
	return (
		<Layout>
      <Placeholder width="10" />
			<h3>
				Informatii despre contul meu  {protectedContent.test}
				<ul>
				  <li>Cod client: {protectedContent.rowCompanie.id ?? <Placeholder width="10" />}</li>
					<li>Denumire: {protectedContent.rowCompanie.denumire ?? <Placeholder width="30" />}</li>
					<li>CIF: {protectedContent.rowCompanie.cui ?? <Placeholder width="20" />}</li>
					<li>Email: {protectedContent.rowCompanie.email ?? <Placeholder />}</li>
					<li>Adresa: {protectedContent.rowCompanie.adresa ?? <Placeholder />}</li>
					<li>Oras: {protectedContent.rowCompanie.oras ?? <Placeholder />}</li>
					<li>Judet: {protectedContent.rowCompanie.judet ?? <Placeholder />}</li>
					<li>Tara: {protectedContent.rowCompanie.tara ?? <Placeholder />}</li>
				</ul>
			</h3>
		</Layout>
    
	)
  */
}
