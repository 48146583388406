export default function FacturaElectronica(props) {
  return (
    <>
      <div className="card">
        <div className="card-header">
            <h4 className="card-title text-success">Save paper - think before you print</h4>
        </div>
        <div className="card-body">
          <div className="transaction-widget">
            <ul className="list-unstyled">
              <li className="d-flex">
                <div className="flex-grow-1">
                <p>
                    Vă mulțumim că ați optat pentru factura electronică. 
                  </p>
                  <p>
                    Dacă doriți o altă modalitate de transmitere a facturii, vă rugăm să contactați furnizorul.
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}