import Placeholder from "../Placeholder"


export default function InformatiiClient(props) {
  return (
    <>
      <div className="card">
        <div className="card-header">
            <h4 className="card-title">Informații client</h4>
        </div>
        <div className="card-body">
          <div className="transaction-widget">
            <ul className="list-unstyled">
              <li className="d-flex">
                <div className="flex-grow-1">
                  <p>
                    <small>Denumire client</small>
                  </p>
                  <p className="wallet-address text-dark">{props.info.denumire !== undefined ? props.info.denumire : <Placeholder width={25} /> }</p>
                </div>
              </li>
              <li className="d-flex">
                <div className="flex-grow-1">
                  <p>
                    <small>Cod client</small>
                  </p>
                  <p className="wallet-address text-dark">{props.info.id !== undefined ? props.info.id : <Placeholder width={10} />}</p>
                </div>
              </li>
              <li className="d-flex">
                <div className="flex-grow-1">
                  <p>
                    <small>Tip client</small>
                  </p>
                  <p className="wallet-address text-dark">{props.info.denumire !== undefined ? props.info.tip : <Placeholder width={25} /> }</p>
                </div>
              </li>
              <li className="d-flex">
                <div className="flex-grow-1">
                  <p>
                    <small>CIF</small>
                  </p>
                  <p className="wallet-address text-dark">{props.info.cui !== undefined ? props.info.cui : <Placeholder width={5} />}</p>
                </div>
              </li>
              {/*
              <li className="d-flex">
                <div className="flex-grow-1">
                  <p>
                    <small>Nr. reg. comerț</small>
                  </p>
                  <p className="wallet-address text-dark">{props.info.nr_reg_com !== undefined ? (props.info.nr_reg_com !== '' ? props.info.nr_reg_com : 'N/A') : <Placeholder width={50} /> }</p>
                </div>
              </li>
              */}
              <li className="d-flex">
                <div className="flex-grow-1">
                  <p>
                    <small>Telefon</small>
                  </p>
                  <p className="wallet-address text-dark">{props.info.telefon !== undefined ? (props.info.telefon !== '' ? props.info.telefon : 'N/A') : <Placeholder width={10} />}</p>
                </div>
              </li>
              <li className="d-flex">
                <div className="flex-grow-1">
                  <p>
                    <small>Email</small>
                  </p>
                  <p className="wallet-address text-dark">{props.info.email !== undefined ? (props.info.email !== '' ? props.info.email : 'N/A') : <Placeholder width={10} />}</p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}