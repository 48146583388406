import React, { Component } from "react";
//  import ReactApexChart from "react-apexcharts";

import Chart from 'react-apexcharts';

//  import { Link } from 'react-router-dom';
import settings from "../../Settings";

/*
var data1 = [
    10.95,
    11.34,
    11.18,
    11.05,
    0,
    23.05,
    14
]


// var data2 = [
//     // [1327359600000, 10.95],
//     // [1327446000000, 11.34],
//     // [1327532400000, 41.18],
//     // [1327618800000, 11.05],
//     ['2021-01', 15.95],
//     ['2021-02', 11.34],
//     ['2021-03', 21.18],
//     ['2021-04', 11.05],
    
// ]

var data2 = [
  12.95,
  14.34,
  21.18,
  22.05,
  0,
  0,
  44.15
  
]

var data3 = [
  42.95,
  14.34,
  21.18,
  22.05,
  0,
  0,
  44.15
  
]

var data4 = [
  32.95,
  34.34,
  21.18,
  22.05,
  0,
  0,
  44.15
  
]
*/

/*
let dataSeries = [{
    name: '2022',
    data: [
      700, 634, 1023, 532, 775, 750, 1030, 904, 875, 1071, 875, -498
    ]
  },
  {
    name: '2021',
    data: [
      0, 761, 564,-552, 7, -2333, 1982, 529,-335,400,-33,33
      
    ]
  }
  
  
  ];
*/

/*
const options = {
  chart: {
    type: 'line',
    height: 350
  },
  xaxis: {
    categories: ['Ian', 'Feb', 'Mar', 'Apr', 'Mai', 'Iun', 'Iul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
  },
  yaxis: {
    title: {
      text: 'Consum [kWh]'
    }
  },
  colors: ['#FF4B9A', '#5216F0', '#a8be04', '#04be83']
};

const series = [
  {
    name: 'Serie 1',
    data: [30, 40, 35, 50, 49, 60, 70, 91, 125, 100, 90, 85]
  },
  {
    name: 'Serie 2',
    data: [23, 43, 33, 55, 67, 83, 89, 101, 99, 88, 77, 65]
  },
  {
    name: 'Serie 3',
    data: [40, 60, 55, 70, 60, 75, 85, 100, 125, 110, 95, 90]
  },
  {
    name: 'Serie 4',
    data: [35, 50, 45, 60, 70, 80, 95, 115, 105, 92, 78, 70]
  },
  {
    name: 'Serie 5',
    data: [50, 70, 60, 75, 80, 90, 105, 120, 130, 115, 105, 95]
  }
];
*/


class GraficCitiri extends Component {
  constructor(props) {
    super(props);

    /*
    let dataSeries = [];
    
    Object.keys(props.dataSeries).map((key)=>{
      dataSeries.push({
        name: key,
        data: [...Object.values(props.dataSeries[key])]
      });
      console.log(Object.values(props.dataSeries[key]));
    });
    //  dataSeriesClone = [ ...dataSeries ];
    //  const dataSeriesClone = JSON.parse(JSON.stringify(dataSeries));
    */

    // const dataSeries = [
    //   {
    //     name: "2021",
    //     data: [0, 761, 564, -552, 7, -2333, 1982, 529, -335, 400, -33, 33]
    //   },
    //   {
    //     name: "2022",
    //     data: [700, 634, 1023, 532, 775, 750, 1030, 904, 875, 1071, 875, -498]
    //   }
    // ];

    //  console.log(dataSeries);
    //  console.log(dataSeriesClone);
    
    /*
    let dataSeries = [];
    Object.keys(props.dataSeries).map((key)=>{
      dataSeries.push({
        name: key,
        data: [1000, 2000],
        //  data: [key*1, key*1-10, key*1+10],
        data: Object.values(props.dataSeries[key])
      });
      console.log(Object.values(props.dataSeries[key]));
    });
    console.log(dataSeries);
    */

    //  console.log(dataSeries, props.dataSeries);

    // this.state = {
    //   series: dataSeries,
    //   options: {
    //     chart: {
    //       type: "area",
    //       height: 300,
    //       foreColor: "#8C87C2",
    //       fontFamily: 'Rubik, sans-serif',
    //       stacked: true,
    //       dropShadow: {
    //         enabled: true,
    //         enabledSeries: [0],
    //         top: -2,
    //         left: 2,
    //         blur: 5,
    //         opacity: 0.06
    //       },
    //       toolbar: {
    //         show: false,
    //       }
    //     },
    //     xaxis: {
    //       categories: Object.values(settings.miscellaneous.luni),
    //     },
    //     colors: ['#FF4B9A', '#5216F0', '#a8be04', '#04be83'],
    //     stroke: {
    //       curve: "smooth",
    //       width: 3
    //     },
    //     dataLabels: {
    //       enabled: false
    //     },

    //     markers: {
    //       size: 0,
    //       strokeColor: "#fff",
    //       strokeWidth: 3,
    //       strokeOpacity: 1,
    //       fillOpacity: 1,
    //       hover: {
    //         size: 6
    //       }
    //     },
    //     yaxis: {
    //       labels: {
    //         offsetX: -10,
    //         offsetY: 0
    //       },
    //       tooltip: {
    //         enabled: true,
    //       }
    //     },
    //     grid: {
    //       show: false,
    //       padding: {
    //         left: -5,
    //         right: 5
    //       }
    //     },
        
    //     legend: {
    //       position: 'top',
    //       horizontalAlign: 'left'
    //     },
    //     fill: {
    //       type: 'gradient',
    //       gradient: {
    //         shadeIntensity: 1,
    //         opacityFrom: 0.5,
    //         opacityTo: 0,
    //         stops: [0, 100, 100]
    //       }
    //     }
    //   }
    // };

    let dataSeries = [];
    Object.keys(props.dataSeries).map((key)=>{
      dataSeries.push({
        name: key,
        data: Object.values(props.dataSeries[key])
      });
      return null;
    });

    this.state = {
      series: dataSeries,

      options: {
        chart: {
          type: 'line',
          height: 350
        },
        xaxis: {
          categories: Object.values(settings.miscellaneous.luni),
          tooltip: {
            enabled: false,
          }
        },
        stroke: {
          curve: "smooth",
          width: 3
        },   
             
        yaxis: {
          title: {
            text: 'Consum [kWh]'
          }
        },
        colors: ['#FF4B9A', '#5216F0', '#a8be04', '#04be83']
      }
    };
    
    /*
    const series = [
      {
        name: 'Serie 1',
        data: [30, 40, 35, 50, 49, 60, 70, 91, 125, 100, 90, 85]
      },
      {
        name: 'Serie 2',
        data: [23, 43, 33, 55, 67, 83, 89, 101, 99, 88, 77, 65]
      },
      {
        name: 'Serie 3',
        data: [40, 60, 55, 70, 60, 75, 85, 100, 125, 110, 95, 90]
      },
      {
        name: 'Serie 4',
        data: [35, 50, 45, 60, 70, 80, 95, 115, 105, 92, 78, 70]
      },
      {
        name: 'Serie 5',
        data: [50, 70, 60, 75, 80, 90, 105, 120, 130, 115, 105, 95]
      }
    ];
    */
  }

  //  <ReactApexChart options={this.state.options} series={this.state.series} type="area" height={350} />
  render() {
    return (
      <div className="card profile_chart transparent">
        <div className="card-header">
          <div className="chart_current_data">
            <h3>
                Grafic consum
            </h3>
            <p className="text-success">
                Pentru ultimele 36 de luni
            </p>
          </div>
        </div>
        <div id="chart-timeline">
          <Chart options={this.state.options} series={this.state.series} type="line" height={350} />
        </div>
      </div>
    );
  }
}

export default GraficCitiri;
