import React, {useState} from "react";
import { useCookies } from "react-cookie";

import settings from "../Settings";
import { fetchProtectedData } from "../Requests";

import LayoutLogged from "../Components/LayoutLogged"

import ChangePasswordForm from "../Components/ChangePassword/ChangePasswordForm";
import ChangePasswordFormSuccess from "../Components/ChangePassword/ChangePasswordFormSuccess";

export default function ChangePassword() {
  const [cookie] = useCookies([settings.auth.cookie_token]);

  const [error, setError] = useState([]);
	const [loading, setLoading] = useState(0);
  const [protectedContent, setProtectedContent] = useState([]);

  const [password, setPassword] = useState('');
  const [checkPassword, setCheckPassword] = useState('');


  const handleSubmit = (event) => {
    event.preventDefault();
		setLoading(1);	//	se marcheaza ca se incarca

    fetchProtectedData(
      settings.url.auth.change_password,
      cookie, 
      {
        password, checkPassword
      },
      {setProtectedContent, setError, setLoading}
    )
  };

  if(protectedContent !== "ok") {
		return (
      <LayoutLogged>
			  <ChangePasswordForm error={error} loading={loading} handleSubmit={handleSubmit} setPassword={setPassword} setCheckPassword={setCheckPassword} />
      </LayoutLogged>
		)
	} else {
		return (
      <LayoutLogged>
			  <ChangePasswordFormSuccess />
      </LayoutLogged>
		)
	}

  
}