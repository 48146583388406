import { Submit } from "../FormInputs"

export default function ContAsociatForm(props) {
  return (
    <div className="card">
      <div className="card-header">
        <h4 className="card-title">Adăugare loc de consum nou</h4>
      </div>
      <div className="card-body">
        <p>
          Poți adăuga mai multe locuri de consum în același cont, folosind datele de pe factura noului loc de consum. 
        </p>
        <p>
          Astfel, poți avea acces pe același cont My.luxten.com la mai multe locuri de consum. Din această secțiune poți seta care loc de consum este activ la un moment dat.
        </p>
        <p>Atenție: primul loc asociat odată cu crearea contului, nu poate fi șters!</p>
        <form
          method="post"
          className="signup_validate"
          onSubmit={props.handleSubmit}
        >
          <div className="mb-3">
              <label>Cod client</label>
              {props.error !== null ? <div className="mb-3 text-danger">{props.error.codClient}</div> : null}
              <input
                type="text"
                placeholder="Codul client de pe factură"
                name="codClient"
                className={props.error?.codClient !== undefined ? "border-danger form-control" : "form-control"}
                onChange={(event) => props.setCodClient(event.target.value)}
              />
          </div>
          <div className="mb-3">
              <label>ID factura</label>
              {props.error !== null ? <div className="mb-3 text-danger">{props.error.idFactura}</div> : null}
              <input
                type="text"
                placeholder="ID factura de pe factură"
                name="idFactura"
                className={props.error?.idFactura !== undefined ? "border-danger form-control" : "form-control"}
                onChange={(event) => props.setIdFactura(event.target.value)}
              />
          </div>
          <div className="text-center mt-4">
            <Submit loading={props.loading} title="Adăugare" />
          </div>
        </form>
      </div>
    </div>
  )
}