import React, {useState} from "react";

import { fetchData } from "../Requests";
import settings from "../Settings";

import ForgotPasswordForm from "../Components/ForgotPassword/ForgotPasswordForm";
import ForgotPasswordFormSuccess from "../Components/ForgotPassword/ForgotPasswordFormSuccess";

function ForgotPassword() {
  const [error, setError] = useState([]);
	const [loading, setLoading] = useState(0);
  const [responseOk, setResponseOk] = useState([]);

  const [username, setUsername] = useState('');


  const handleSubmit = (event) => {
    event.preventDefault();
		setLoading(1);	//	se marcheaza ca se incarca

    fetchData(settings.url.auth.forgot_password,
			{username}, 
			{setError, setResponseOk, setLoading}
		)
  };

  if(responseOk !== "ok") {
		return (
			<ForgotPasswordForm error={error} loading={loading} handleSubmit={handleSubmit} setUsername={setUsername} />
		)
	} else {
		return (
			<ForgotPasswordFormSuccess />
		)
	}
}

export default ForgotPassword