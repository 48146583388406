import Placeholder from "../Placeholder"

export default function PuncteConsum(props) {
  return (
    <>
      <div className="card">
        <div className="card-header">
          <h4 className="card-title">Puncte de consum</h4>
        </div>
        <div className="card-body pt-0">
          <div className="transaction-table">
            <div className="table-responsive">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th className="text-center col-10">Adresa completă</th>
                    <th className="text-center col-1">Localitate</th>
                    <th className="text-center col-1">Judet</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    props.info.map((row, key)=>{
                      return(
                        <tr key={key}>
                          <td className="text-center">
                            {row.strada !== undefined 
                            ? (
                              row.strada + 
                              ((row.nr_strada !== '') ? ", nr. " + row.nr_strada : "") +
                              ((row.bloc !== '') ? ", bloc " + row.bloc : "") +
                              ((row.scara !== '') ? ", scara " + row.scara : "") +
                              ((row.apt !== '') ? ", apt. " + row.apt : "") +
                              ((row.cod_postal !== '') ? ", cod postal " + row.cod_postal : "")
                              ) 
                            : <Placeholder width={5} />}
                            
                          </td>
                          <td className="text-end">{row.oras !== undefined ? row.oras : <Placeholder width={5} />}</td>
                          <td className="text-end">{row.judet !== undefined ? row.judet : <Placeholder width={5} />}</td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}