import React, {useState, useRef	} from "react";

import { fetchData } from "../Requests";
import settings from "../Settings";

import SignUpForm from "../Components/SignUp/SignUpForm";
import SignUpFormSuccess from "../Components/SignUp/SignUpFormSuccess";

function SignUp() {
  const [error, setError] = useState([]);
	const [loading, setLoading] = useState(0);
  const [responseOk, setResponseOk] = useState([]);

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [checkPassword, setCheckPassword] = useState('');
  const [codClient, setCodClient] = useState('');
  const [idFactura, setIdFactura] = useState('');
	const [acord, setAcord] = useState(false);
	const [isVerified, setIsVerified] = useState(false);

	const recaptchaRef = useRef(null);



  const handleSubmit = (event) => {
    event.preventDefault();

		setLoading(1);	//	se marcheaza ca se incarca

    fetchData(settings.url.auth.sign_up,
			{
      username,
			password,
			checkPassword,
			codClient,
			idFactura,
			acord,
			isVerified
    	}, 
			{setError, setResponseOk, setLoading}
		)

		recaptchaRef.current.reset();
  };

	if(responseOk !== "ok") {
		return (
			<SignUpForm error={error} loading={loading} handleSubmit={handleSubmit} setUsername={setUsername} setPassword={setPassword} setCheckPassword={setCheckPassword} setCodClient={setCodClient} setIdFactura={setIdFactura} setAcord={setAcord} recaptchaRef={recaptchaRef} setIsVerified={setIsVerified}  />
		)
	} else {
		return (
			<SignUpFormSuccess />
		)
	}

}

export default SignUp