
import { Submit } from "../FormInputs"
export default function TransmitereIndexForm(props) {

  return (
    <form
      method="post"
      className="mt-3"
      onSubmit={props.handleSubmit}
    >
      <div className="text-start mt-4">
        <div className="mb-3">
          <label>Introduceți indexul curent:</label>
          {props.error !== null ? <div className="mb-3 text-danger">{props.error.indexAutocitit}</div> : null}
          <input
            type="text"
            placeholder="Index"
            name="indexAutocitit"
            className={props.error?.indexAutocitit !== undefined ? "border-danger form-control" : "form-control"}
            onChange={(event) => props.setIndexAutocitit(event.target.value)}
          />
        </div>
        <Submit loading={props.loading} title="Transmitere index" />        
      </div>
    </form>
  )
}