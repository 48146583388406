import React, {useState, useEffect} from 'react';
import { useCookies } from 'react-cookie';

import moment from "moment";

import settings from '../Settings';
import { fetchProtectedData } from '../Requests';

import LayoutLogged from '../Components/LayoutLogged';
import Placeholder from '../Components/Placeholder';


export default function PlatileMele() {
  const [, setError] = useState(null);
	const [cookie] = useCookies([settings.auth.cookie_token]);
  const [protectedContent, setProtectedContent] = useState({
    rowIncasari: []
  });

	useEffect(() => {
    setProtectedContent({
      rowIncasari: [
        {}
      ]
    });

    fetchProtectedData(
      settings.url.contul_meu.plati,
      cookie, 
      {},
      {setProtectedContent, setError}
      )

	}, [cookie])  //  array gol, ruleaza o singura data. ruleaza de fiecare data cand este modificat ceva in [] 


	return (
		<LayoutLogged>
      <div className="card">
          <div className="card-header">
              <h4 className="card-title">Plățile mele</h4>
          </div>
          <div className="card-body pt-0">
              <div className="transaction-table">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th className="text-center col-3">Dată încasare</th>
                      <th className="text-center col-3">Valoare plătită</th>
                      <th className="text-center col-3">Stare încasare</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      protectedContent.rowIncasari.map((value, index)=>{
                        return(
                          <tr key={index} className="border">
                            <td className="fw-normal text-center">{value.data ? moment(value.data).format("DD/MM/YYYY") : <Placeholder />}</td>
                            <td className="fw-normal text-center">{value.valoare_lei ? value.valoare_lei : <Placeholder />}</td>
                            <td className="fw-normal text-center"> {value.descriere_status ? <span className='btn btn-info p-1'>{value.descriere_status}</span> : <Placeholder />}</td>
                          </tr>
                        )
                      })
                    }
                  </tbody>
                </table>
              </div>
          </div>
      </div>
		</LayoutLogged>
	)
}