import React from "react";

import {Link} from "react-router-dom";
import HeaderUnLogged from "../HeaderUnLogged";

import { Submit } from "../FormInputs"

export default function ResendEmailForm(props) {
  return (
    <>
      <HeaderUnLogged />
      <div className="authincation section-padding">
        <div className="container h-100">
          <div className="row justify-content-center h-100 align-items-center">
            <div className="col-xl-5 col-md-6 mt-5">
              {/*
              <div className="mini-logo text-center my-5">
                <Link to={"/"}><img src={require("./../images/logo.png")} alt="" /></Link>
              </div>
              */}
              <div className="auth-form card">
                  <div className="card-header justify-content-center">
                    <h4 className="card-title">Validare cont</h4>
                  </div>
                  <div className="card-body">
                      <form
                        method="post"
                        className="signup_validate"
                        onSubmit={props.handleSubmit}
                      >
                      <div className="mb-3">
                          <label>Introduceți adresa de email folosită la înregistrare</label>
                          {props.error !== null ? <div className="mb-3 text-danger">{props.error.username}</div> : null}
                          <input
                            type="text"
                            placeholder="O adresa de email validă"
                            name="username"
                            className={props.error?.username !== undefined ? "border-danger form-control" : "form-control"}
                            onChange={(event) => props.setUsername(event.target.value)}
                          />
                      </div>
                      <div className="text-center mt-4">
                        <Submit loading={props.loading} title="Retrimitere email" />
                      </div>
                  </form>
                  <div className="new-account mt-3">
                    <p>
                      Ai deja cont?{" "}
                      <Link
                        className="text-primary"
                        to={"/"}
                      >
                        Autentificare
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}