export function Submit(props) {
  return (
    props.loading === 1 
    ? (
      <button type="submit" className="btn btn-success w-100" disabled>
        <div className="spinner-border text-dark"></div>
        <div>{props.title}</div>
      </button>
    ) 
    : (
      <input type="submit" className="btn btn-success w-100" value={props.title} />
    )
  );
}