import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";

import { fetchData } from "../Requests";
import settings from "../Settings";

import HeaderUnLogged from "../Components/HeaderUnLogged";
import Loading from "./Loading";

export default function ActivateAccount() {
  const { jwt } = useParams();

  const [error, setError] = useState(null);
	const [loading, setLoading] = useState(1);
  const [responseOk, setResponseOk] = useState(null);



  useEffect(() => {
    fetchData(settings.url.auth.activate_account,
      {ui: jwt}, 
      {setError, setResponseOk, setLoading}
    );
  }, [jwt])

  switch(true) {
    case loading === 0:
      return (
        <>
          <HeaderUnLogged />
          <div className="authincation section-padding">
            <div className="container h-100">
              <div className="row justify-content-center h-100 align-items-center">
                <div className="col-xl-5 col-md-6 mt-5">
                  
                  <div className="auth-form card">
                      <div className="card-header justify-content-center">
                        <h4 className="card-title">Validare cont</h4>
                      </div>
                      <div className="card-body">
                        {error !== null 
                          ?  <div className="mb-3 text-danger">{error.ui}</div>
                          : null
                        }
                        {responseOk !== null 
                          ?  <div className="mb-3"><h4>Contul a fost validat cu succes!</h4><p>Felicitări! Contul dvs. a fost activat cu succes. Acum puteți accesa contul creat.</p></div>
                          : null
                        }

                        <div className="new-account mt-3">
                          <p>
                            <Link
                              className="text-primary"
                              to={"/"}
                            >
                              Întoarcere la pagina de autentificare
                            </Link>
                          </p>
                        </div>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) 
    // initial se afiseaza Loading ...
    default:
      return (
        <Loading />
      )
  }

}