import React, {useState, useEffect} from "react";
import { useCookies } from 'react-cookie';

import settings from "../Settings";
import { fetchDataUserGetInfo } from "../Requests";
import {ContextLogin} from "../Contexts/Login";

import Header from './Header';
import Sidebar from "./Sidebar";
import HeaderUnLogged from "./HeaderUnLogged"

import Loading from '../Pages/Loading';
import Error from '../Pages/Error';

/**
 * returneaza un layout personalizat: daca este logat, cu designul de logat, in caz contrar, designul de nelogat
 * @param {*} param0 
 * @returns 
 */
export default function LayoutLoggedUnLogged({children}) {
  const [contextLogged, setContextLogged] = useState({}); 

  const [error, setError] = useState(null);
  const [cookie] = useCookies([settings.auth.cookie_token]);
  
  useEffect(() => {

    fetchDataUserGetInfo(setContextLogged, setError, cookie);
      
  }, [cookie])  //  array gol, deci ruleaza o singura data! 

  switch(true) {
    // raspuns ok de la server: nu este autentificat, se cere autentificarea
    case contextLogged.err !== undefined:
      return (
        <ContextLogin.Provider value={[contextLogged, setContextLogged]}>
          <HeaderUnLogged />
          <div className="content-body">
            <div className="container">
              <div className="row">
                <div className="col-xl-12">{children}</div>
              </div>
            </div>
          </div>
      </ContextLogin.Provider>
      )
    // raspuns pozitiv de la server: este autentificat, se afiseaza pagina logata
    case contextLogged.ok !== undefined:
      return (
      <ContextLogin.Provider value={[contextLogged, setContextLogged]}>
        <Header />
        <Sidebar />
        <div className="content-body">
          <div className="container">
            <div className="row">
              <div className="col-xl-12">{children}</div>
						</div>
					</div>
				</div>
      </ContextLogin.Provider>
      )
    // exista eroare cand se incearca fetch
    case error !== null:
      return (
        <Error error={error} />
      )
    // initial se afiseaza Loading ...
    default:
      return (
        <Loading />
      )
  }
  

}
