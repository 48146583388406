import Placeholder from "../Placeholder"

export default function AdresaFacturare(props) {
  return (
    <>
      <div className="card">
        <div className="card-header">
            <h4 className="card-title">Adresa de facturare</h4>
        </div>
        <div className="card-body">
          <div className="transaction-widget">
            <ul className="list-unstyled">
              <li className="d-flex">
                <div className="flex-grow-1">
                  <p className="wallet-address text-dark">
                    {props.info.strada !== undefined 
                      ? (
                        ((props.info.strada !== null) ? props.info.strada : "N/A") + 
                        ((props.info.nr_strada !== '') ? ", nr. " + props.info.nr_strada : "") +
                        ((props.info.bloc !== '') ? ", bloc " + props.info.bloc : "") +
                        ((props.info.scara !== '') ? ", scara " + props.info.scara : "") +
                        ((props.info.apt !== '') ? ", apt. " + props.info.apt : "") +
                        ((props.info.cod_postal !== '') ? ", cod postal " + props.info.cod_postal : "") +
                        ((props.info.oras !== null) ? ", " + props.info.oras : "") +
                        ((props.info.judet !== null) ? ", " + props.info.judet : "")
                        )
                      : <Placeholder width={50} /> }
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}