
const settings = {

  url: {
    auth: {
      /**
       * se trimit datele de autentificare si se raspunde daca este ok sau nu
       */
      login: process.env.REACT_APP_API_URL + '/auth/login',
      /**
       * delogarea utilizatorului curent
       */
      logout: process.env.REACT_APP_API_URL + '/auth/logout',
      /**
       * datele utilizatrului logat
       */
      get_info: process.env.REACT_APP_API_URL + '/auth/get_info',

      sign_up: process.env.REACT_APP_API_URL + '/auth/sign_up',
      activate_account: process.env.REACT_APP_API_URL + '/auth/activate_account',      
      resend_email: process.env.REACT_APP_API_URL + '/auth/resend_email',
      change_password: process.env.REACT_APP_API_URL + '/auth/change_password',
      forgot_password: process.env.REACT_APP_API_URL + '/auth/forgot_password',
      reset_password: process.env.REACT_APP_API_URL + '/auth/reset_password',
    },
    contul_meu: {
      index: process.env.REACT_APP_API_URL + '/contul_meu',
      istoric_facturi: process.env.REACT_APP_API_URL + '/contul_meu/istoric_facturi',
      plati: process.env.REACT_APP_API_URL + '/contul_meu/plati',
      contracte: process.env.REACT_APP_API_URL + '/contul_meu/contracte',
      conturi_asociate: process.env.REACT_APP_API_URL + '/contul_meu/conturi_asociate',
      add_asociere_cont: process.env.REACT_APP_API_URL + '/contul_meu/add_asociere_cont',
      add_index_autocitit: process.env.REACT_APP_API_URL + '/contul_meu/add_index_autocitit',
      set_asociere: process.env.REACT_APP_API_URL + '/contul_meu/set_asociere',
      istoric_citiri: process.env.REACT_APP_API_URL + '/contul_meu/istoric_citiri',
      download_istoric_citiri: process.env.REACT_APP_API_URL + '/contul_meu/download_istoric_citiri',
      download_factura: process.env.REACT_APP_API_URL + '/contul_meu/download_factura',
      plateste_factura: process.env.REACT_APP_API_URL + '/plateste_factura/online/',
      get_raspuns_plata: process.env.REACT_APP_API_URL + '/plateste_factura/get_raspuns_plata/',
    },

    frontend: {
      despre_noi: '/despre_noi',
      despre_cookie: '/despre_cookie',
      t_c: '/termeni_conditii',
      sign_up: '/sign_up',
      resend_email: '/resend_email',
      activate_account: '/activate_account',
      forgot_password: '/forgot_password',
      reset_password: '/reset_password',
      change_password: '/change_password',
      facturile_mele: '/facturile_mele',
      transmitere_index: '/transmitere_index',
      contracte: '/contracte',
      platile_mele: '/platile_mele',
      conturi_asociate: '/conturi_asociate',
      istoric_indecsi: '/istoric_indecsi',
      raspuns_plata: '/raspuns_plata'
    }

  },
  html: {
    'title': {
      'ContulMeu': 'My.luxten.com - Contul meu'
    }
  },

  auth: {
    /**
     * tipul de referrer
     */
    ref: 1,
    cookie_token: '_ut',
    /**
     * optiunile cand se seteaza
     */
    cookie_options: {
      path: '/',
      secure: process.env.REACT_APP_COOKIE_SECURE,
      httpOnly: false,
      sameSite: 'strict'
      //  expires
    }
    // cookie_expire: 3600*4
  },

  website: {
    title: 'MyLuxten',
    companyName: 'Luxten Lighting Company S.A.',
  },

  /**
   * diferite mesaje predefinite
   */
  messages: {
    /**
     * serverul nu raspund
     */
    server_error: 'Eroare raspuns server. Încercați mai târziu.',
    /**
     * serverul raspunde anormal, de exemplu nu cu un json in formatul acceptat
     */
    server_unknown_error: 'Undefined error!',
  },
    
  /**
   * alte variabile 
   */
  miscellaneous: {
    /**
     * prima cheie default la care raspunde errorul unui request POST
     */
    firstKey: "__first_key",
    istoric_consum_download_file: "istoric_consum.xlsx",
    download_factura: "factura.pdf",
    luni: {
      1: "Ianuarie", 
      2: "Februarie", 
      3: "Martie", 
      4: "Aprilie", 
      5: "Mai", 
      6: "Iunie", 
      7: "Iulie", 
      8: "August", 
      9: "Septembrie", 
      10: "Octombrie", 
      11: "Noiembrie", 
      12: "Decembrie", 
    },
    recaptcha_secret_key: "6Ldo1FMlAAAAACVeaTZZbw7rmQijpECzvr_35E_M"
  }
};

export default settings;