import React from "react";
import {Link} from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";

import settings from "../../Settings";

import HeaderUnLogged from "../HeaderUnLogged";
import { Submit } from "../FormInputs";

export default function SignUpForm(props) {

  return (
    <>
      <HeaderUnLogged />
      <div className="authincation section-padding">
        <div className="container h-100">
          <div className="row justify-content-center h-100 align-items-center">
            <div className="col-xl-5 col-md-6 mt-5">
              {/*
              <div className="mini-logo text-center my-5">
                <Link to={"/"}><img src={require("./../images/logo.png")} alt="" /></Link>
              </div>
              */}
              <div className="auth-form card">
                  <div className="card-header justify-content-center">
                    <h4 className="card-title">Creare cont</h4>
                  </div>
                  <div className="card-body">
                      <form
                        method="post"
                        className="signup_validate"
                        onSubmit={props.handleSubmit}
                      >
                      <div className="mb-3">
                          <label>Adresa de email</label>
                          {props.error !== null ? <div className="mb-3 text-danger">{props.error.username}</div> : null}
                          <input
                            type="text"
                            placeholder="O adresa de email validă"
                            name="username1"
                            className={props.error?.username !== undefined ? "border-danger form-control" : "form-control"}
                            onChange={(event) => props.setUsername(event.target.value)}
                          />
                      </div>
                      <div className="mb-3">
                          <label>Parolă</label>
                          {props.error !== null ? <div className="mb-3 text-danger">{props.error.password}</div> : null}
                          <input
                            type="password"
                            placeholder="Introdu o parolă puternică"
                            name="password"
                            className={props.error?.password !== undefined ? "border-danger form-control" : "form-control"}
                            onChange={(event) => props.setPassword(event.target.value)}
                          />
                      </div>
                      <div className="mb-3">
                          <label>Reintrodu parola</label>
                          {props.error !== null ? <div className="mb-3 text-danger">{props.error.checkPassword}</div> : null}
                          <input
                            type="password"
                            placeholder="Reintrodu parola"
                            name="checkPassword"
                            className={props.error?.checkPassword !== undefined ? "border-danger form-control" : "form-control"}
                            onChange={(event) => props.setCheckPassword(event.target.value)}
                          />
                      </div>
                      <div className="mb-3">
                          <label>Cod client</label>
                          {props.error !== null ? <div className="mb-3 text-danger">{props.error.codClient}</div> : null}
                          <input
                            type="text"
                            placeholder="Codul client de pe factură"
                            name="codClient"
                            className={props.error?.codClient !== undefined ? "border-danger form-control" : "form-control"}
                            onChange={(event) => props.setCodClient(event.target.value)}
                          />
                      </div>
                      <div className="mb-3">
                          <label>ID factura</label>
                          {props.error !== null ? <div className="mb-3 text-danger">{props.error.idFactura}</div> : null}
                          <input
                            type="text"
                            placeholder="ID factura de pe factură"
                            name="idFactura"
                            className={props.error?.idFactura !== undefined ? "border-danger form-control" : "form-control"}
                            onChange={(event) => props.setIdFactura(event.target.value)}
                          />
                      </div>

                      <div className="mb-3">
                          {props.error !== null ? <div className="mb-3 text-danger">{props.error.isVerified}</div> : null}
                          <ReCAPTCHA ref={props.recaptchaRef} sitekey={settings.miscellaneous.recaptcha_secret_key}  onChange={(event) => {props.setIsVerified(event)}  } />
                      </div>
            
                      <div className="mb-3">
                          {props.error !== null ? <div className="mb-3 text-danger">{props.error.acord}</div> : null}
                          <label className="toggle">
                            <input
                              className="toggle-checkbox"
                              type="checkbox"
                              name="acord" id="acord"
                              checked={props.acord}
                              onChange={(event)=>{props.setAcord(event.target.checked)}}
                            />
                            <span className="toggle-switch"></span>
                            <span className="toggle-label">
                                Am citit și sunt de acord cu <Link to={settings.url.frontend.despre_cookie} className="text-primary">Politica de cookie-uri</Link> precum si cu <Link to={settings.url.frontend.t_c} className="text-primary">Termeni și condiții</Link>.
                            </span>
                        </label>
                      </div>
                      <div className="text-center mt-4">
                        <Submit loading={props.loading} title="Înregistrare" />
                      </div>
                  </form>
                  <div className="new-account mt-3">
                    <p>
                      Ai deja cont?{" "}
                      <Link
                        className="text-primary"
                        to={"/"}
                      >
                        Autentificare
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}