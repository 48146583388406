export default function ContAsociatFormSuccess() {
  return (
    <div className="card">
      <div className="card-header">
        <h4 className="card-title">Adăugare loc de consum nou</h4>
      </div>
      <div className="card-body">
        <p>
          Noul loc de consum a fost adăugat cu succes contului tău si a fost setat ca implicit.
        </p>
      </div>
    </div>
  )
}