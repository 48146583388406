import React from "react";
import {Link} from "react-router-dom";

import HeaderUnLogged from "../HeaderUnLogged";

export default function ResendEmailFormSuccess() {
  return (
    <>
      <HeaderUnLogged />
      <div className="authincation section-padding">
        <div className="container h-100">
          <div className="row justify-content-center h-100 align-items-center">
            <div className="col-xl-5 col-md-6 mt-5">
              <div className="auth-form card">
                <div className="card-header justify-content-center">
                  <h4 className="card-title">Retrimitere email validare</h4>
                </div>
                <div className="card-body">
                  <p>Emailul de validare a fost trimis cu succes!</p>
                  <p>Dacă nu ați primit emailul de validare, încercați sa căutați și in folderul de spam.</p>
                  <div className="new-account mt-3">
                    <p>
                    <Link className="text-primary" to={"/"}>
                      Întoarcere la pagina de autentificare
                    </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}