
import Placeholder from "../Placeholder"

export default function InformatiiContract(props) {
  return (
  <div className="card">
    <div className="card-header">
        <h4 className="card-title">Informații contract</h4>
    </div>
    <div className="card-body">
      <div className="transaction-widget">
        <div className="row">
            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 mb-4">
              <p>
                ID contract
              </p>
              <h3>{props.info.id !== undefined ? props.info.id : <Placeholder width={5} /> } </h3>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 mb-4">
              <p>
                <small>Categorie</small>
              </p>
              <p className="wallet-address text-dark"> {props.info.titlu_categorie !== undefined ? props.info.titlu_categorie : <Placeholder width={15} /> } </p>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 mb-4">
              <p>
                <small>Distribuitor</small>
              </p>
              <p className="wallet-address text-dark"> {props.info.titlu_distribuitor !== undefined ? props.info.titlu_distribuitor : <Placeholder width={15} /> } </p>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 mb-4">
              <p>
                <small>Tip tensiune</small>
              </p>
              <p className="wallet-address text-dark">{props.info.titlu_tip_tensiune !== undefined ? props.info.titlu_tip_tensiune : <Placeholder width={5} />}</p>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 mb-4">
              <p>
                <small>POD</small>
              </p>
              <p className="wallet-address text-dark">{props.info.pod !== undefined ? props.info.pod : <Placeholder width={20} />}</p>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 mb-4">
              <p>
                <small>Serie contor</small>
              </p>
              <p className="wallet-address text-dark">{props.info.serie_contor !== undefined ? props.info.serie_contor : <Placeholder width={20} />}</p>
            </div>
        </div>
      </div>
    </div>
  </div>

  )
}