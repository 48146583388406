//  import React, { useState } from 'react';
import React, {useState, useContext, useRef } from "react";
import { useCookies } from 'react-cookie';
import { Link } from 'react-router-dom'
import ReCAPTCHA from "react-google-recaptcha";

import settings from "../Settings";
import { fetchDataLogin } from "../Requests";
import {ContextLogin} from "../Contexts/Login";

import HeaderUnLogged from "../Components/HeaderUnLogged";
import { Submit } from "../Components/FormInputs";

function Login() {

  const [, setContextLogged] = useContext(ContextLogin);
  
  const [loading, setLoading] = useState(0);

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isChecked, setIsChecked] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
	const recaptchaRef = useRef(null);

  const [error, setError] = useState({});
  const [, setCookie] = useCookies([settings.auth.cookie_token]);




  const handleSubmit = (event) => {
    event.preventDefault();

    setLoading(1);	//	se marcheaza ca se incarca

    //  fetchDataLogin(setCookie, setContextLogged, setError, setLoading, username, password, isChecked)
    fetchDataLogin(
      setCookie,
      {
        username,
        password,
        isChecked,
        isVerified
      },
      {
        setContextLogged,
        setError,
        setLoading
      }
    )

    recaptchaRef.current.reset();
  };

  return (
    <>
      <HeaderUnLogged />
      <div className="authincation section-padding">
        <div className="container h-100">
          <div className="row justify-content-center h-100 align-items-center">
            <div className="col-xl-5 col-md-6">
              <div className="auth-form card mt-5">
                <div className="card-header justify-content-center">
                  <h4 className="card-title">Autentificare</h4>
                </div>
                <div className="card-body">
                    <form
                        method="post"
                        className="signin_validate"
                        onSubmit={handleSubmit}
                    >
                        {error !== null && error.username !== "__INACTIV__" ? <div className="mb-3 text-danger">{error.username}</div> : null}
                        {error !== null && error.username === "__INACTIV__" ? <div className="mb-3 text-danger">Click <Link to={settings.url.frontend.resend_email}>aici</Link> daca doriti sa fie retrimis link-ul de validare email.</div> : null}
                        <div className="mb-3">
                            <label>Email</label>
                            <input
                                type="email"
                                className="form-control"
                                placeholder="email@example.com"
                                id="username" 
                                value={username} onChange={(event) => setUsername(event.target.value)}
                            />
                        </div>
                        {error !== null && error.password ? <div className="mb-3 text-danger">{error.password}</div> : null}
                        <div className="mb-3">
                            <label>Parolă</label>
                            <input
                                type="password"
                                className="form-control"
                                placeholder="Parolă"
                                name="password"
                                id="password" value={password} onChange={(event) => setPassword(event.target.value)}
                            />
                        </div>
                        
                        <div className="mb-3">
                          {error !== null ? <div className="mb-3 text-danger">{error.isVerified}</div> : null}
                          <ReCAPTCHA ref={recaptchaRef} sitekey={settings.miscellaneous.recaptcha_secret_key}  onChange={(event) => {setIsVerified(event)}  } />
                        </div>
                       

                        {error !== null ? <div className="mb-3 text-danger">{error.remember_me}</div> : null}
                        <div className="row d-flex justify-content-between mt-4 mb-2">
                            <div className="mb-3 mb-0">
                                <label className="toggle">
                                    <input
                                        className="toggle-checkbox"
                                        type="checkbox"
                                        name="remember_me" id="remember_me"
                                        checked={isChecked}
                                        onChange={(event)=>{setIsChecked(event.target.checked)}}
                                    />
                                    <span className="toggle-switch"></span>
                                    <span className="toggle-label">
                                        Remember me
                                    </span>
                                </label>
                            </div>
                            <div className="mb-3 mb-0">
                                <Link to={settings.url.frontend.forgot_password}>
                                    Parolă uitată?
                                </Link>
                            </div>
                        </div>
                        <div className="text-center">
                          <Submit loading={loading} title="Autentificare" />
                        </div>
                    </form>
                    <div className="new-account mt-3">
                        <p>
                            Nu ai cont?{" "}
                            <Link
                                className="text-primary"
                                to={settings.url.frontend.sign_up}
                            >
                                Crează-ți cont
                            </Link>
                        </p>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>    
  )

  /*
  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={8}>
            <CCardGroup>
              <CCard className="p-4">
                <CCardBody>
                  <CForm onSubmit={handleSubmit}>
                    <h1>My.luxten.com</h1>
                    <p className="text-medium-emphasis">Autentificare</p>
                    <CInputGroup className="mb-3">
                      <CInputGroupText>
                        <CIcon icon={cilUser} />
                      </CInputGroupText>
                      <CFormInput placeholder="Username" autoComplete="username" id="username" value={username} onChange={(event) => setUsername(event.target.value)} />
                    </CInputGroup>
                    <CInputGroup className="mb-4">
                      <CInputGroupText>
                        <CIcon icon={cilLockLocked} />
                      </CInputGroupText>
                      <CFormInput
                        type="password"
                        placeholder="Password"
                        autoComplete="current-password"
                        id="password" value={password} onChange={(event) => setPassword(event.target.value)}
                      />
                    </CInputGroup>
                    <CRow>
                      <CCol xs={6}>
                        <CButton type="submit" color="primary" className="px-4">
                          Login
                        </CButton>
                      </CCol>
                      <CCol xs={6} className="text-right">
                        <CButton color="link" className="px-0">
                          Parola uitata?
                        </CButton>
                      </CCol>
                    </CRow>
                  </CForm>
                </CCardBody>
              </CCard>
              <CCard className="text-white bg-primary py-5" style={{ width: '44%' }}>
                <CCardBody className="text-center">
                  <div>
                    <h2>Creare cont</h2>
                    <p>My Luxten este contul tau online, unde gasesti toate informatiile despre factura ta de energie electrica</p>
                    <p>Pentru a continua pe My Luxten, aveti nevoie de un cont.</p>
                    <Link to="/register">
                      <CButton color="primary" className="mt-3" active tabIndex={-1}>
                        Inregistreaza-te!
                      </CButton>
                    </Link>
                  </div>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>    
  )
  */

  
}

export default Login;