import Placeholder from "../Placeholder";

export default function ContAsociat(props) {
  return (
    <div className="card">
    <div className="card-header">
      <h4 className="card-title">ID client #{props.info.rowCompanie.id !== undefined ? props.info.rowCompanie.id : <Placeholder width={5} /> } ({props.info.rowCompanie.denumire !== undefined ? props.info.rowCompanie.denumire : <Placeholder width={10} /> }) </h4>
    </div>
    <div className="card-body">
      <div className="transaction-widget">
        {
          props.info.rowContracte.map((row, key)=>{
            return(
              <div key={key}>
                {row.strada_consum !== undefined 
                ? (
                  <>
                    <p>
                      {
                        (
                          row.id !== null
                          ? (
                            row.strada_consum + 
                            ((row.nr_strada_consum !== '') ? ", nr. " + row.nr_strada_consum : "") +
                            ((row.bloc_consum !== '') ? ", bloc " + row.bloc_consum : "") +
                            ((row.scara_consum !== '') ? ", scara " + row.scara_consum : "") +
                            ((row.apt_consum !== '') ? ", apt. " + row.apt_consum : "")
                          )
                          : <div className="text-warning border p-3">Acest contract nu mai este activ.</div>
                        )
                        
                      }
                    </p>
                    <p>
                      {row.oras_consum}
                    </p>
                    <p>
                      {row.judet_consum}
                    </p>

                    <div className="d-flex justify-content-between mt-2">
                      <div>
                        {
                          props.info.row.is_selected !== "1"
                          ? (<button className="btn btn-info p-2" onClick={()=>{props.setImplicit(props.info.rowCompanie.id);}}>Setează implicit</button>)
                          : ""
                        }
                      </div>
                      <div>
                        {
                          props.info.row.is_default !== "1" && props.info.row.is_selected !== "1"
                          ? (<button className="btn btn-danger p-2"  onClick={()=>{props.delAsociere(props.info.rowCompanie.id);}}>Șterge asocierea</button>)
                          : ""
                        }
                      </div>
                    </div>
                  </>
                  ) 
                : <Placeholder width={25} />}
              </div>
            )
          })
        }
      </div>
    </div>
  </div>
  )
}