import React from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import CookieConsent from "react-cookie-consent";

import settings from '../Settings'

function HeaderUnLogged() {
  return (
    <>
      <div className="header landing_page">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="navigation">
                <Navbar expand="lg">
                  <Link className="navbar-brand" to={'/'}><
                    img src={require('./../images/logo.png')} alt="" />
                    <span>{settings.website.title}</span>
                  </Link>
                  <Navbar.Toggle aria-controls="basic-navbar-nav" />
                  <Navbar.Collapse>
                    <Nav className="me-auto">
                      <Nav.Item><Link className="nav-link" to="/">Autentificare</Link></Nav.Item>
                      <Nav.Item><Link className="nav-link" to={settings.url.frontend.despre_noi}>Despre {settings.website.title}</Link></Nav.Item>
                      <Nav.Item><Link className="nav-link" to={settings.url.frontend.despre_cookie}>Politica de cookie-uri</Link></Nav.Item>
                      <Nav.Item><Link className="nav-link" to={settings.url.frontend.t_c}>Termeni și condiții</Link></Nav.Item>
                    </Nav>
                  </Navbar.Collapse>
                </Navbar>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CookieConsent
        location="bottom"
        buttonText="Sunt de acord!"
        cookieName="cookie_consent"
        style={{ background: "#2B373B" }}
        expires={2}
      >
        Cookie-urile ne permit să vă oferim online serviciile noastre. Pentru a vă bucura de toate facilitățile website-ului este necesar să le acceptați.{" "}
        <Link to={settings.url.frontend.despre_cookie} className="secondary-text small">Click aici pentru mai multe detalii</Link>
      </CookieConsent>
    </>
  )
//          {/*buttonStyle={{ color: "#4e503b", fontSize: "13px" }}*/}

}

export default HeaderUnLogged;