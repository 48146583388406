import React, {useState, useEffect} from 'react';
import { useCookies } from 'react-cookie';

import settings from '../Settings';
import { fetchProtectedData } from '../Requests';

import LayoutLogged from '../Components/LayoutLogged';
import ContAsociat from '../Components/ConturiAsociate/ContAsociat';
import ContAsociatForm from '../Components/ConturiAsociate/ContAsociatForm';
import ContAsociatFormSuccess from '../Components/ConturiAsociate/ContAsociatFormSuccess';

export default function ConturiAsociate() {
  // pentru autentificare
  const [cookie] = useCookies([settings.auth.cookie_token]);
  
  // pentru lista conturi asociate
  const [, setError] = useState(null);
  const [protectedContent, setProtectedContent] = useState({
    accounts: [
      {
        row: {},
        rowCompanie: {},
        rowContracte: [
          {}
        ]
      }
    ]
  });

  // pentru formular adaugare
  const[addAsociereError, setAddAsociereError] = useState(null);
  const[addAsociereContent, setAddAsociereContent] = useState();
  const[loading, setLoading] = useState(0);
  const[codClient, setCodClient] = useState('');
  const[idFactura, setIdFactura] = useState('');

  // pentru modificarea starii
  const[, setAsociere] = useState();
  const[refresh, setRefresh] = useState(0);
  
  const handleSubmit = (event) => {
    event.preventDefault();
		setLoading(1);	//	se marcheaza ca se incarca

    fetchProtectedData(
			settings.url.contul_meu.add_asociere_cont, 
			cookie, 
			{
        codClient,
			  idFactura
      }, 
			{
        setError: setAddAsociereError, 
        setProtectedContent: setAddAsociereContent,
        setLoading,
        setRefresh,
        refresh //  exceptie, aici ar cam trebui sa fie doar functii
      }
		)

  };

  const setImplicit = (idCompanie) => {
    fetchProtectedData(
			settings.url.contul_meu.set_asociere, 
			cookie, 
			{
        type: "set",
			  id: idCompanie
      }, 
			{
        setProtectedContent: setAsociere,
        setLoading,
        setRefresh,
        refresh //  exceptie, aici ar cam trebui sa fie doar functii
      }
		)
  }

  const delAsociere = (idCompanie) => {
    fetchProtectedData(
			settings.url.contul_meu.set_asociere, 
			cookie, 
			{
        type: "del",
			  id: idCompanie
      }, 
			{
        setProtectedContent: setAsociere,
        setLoading,
        setRefresh,
        refresh //  exceptie, aici ar cam trebui sa fie doar functii
      }
		)
  }

  useEffect(() => {
    fetchProtectedData(
			settings.url.contul_meu.conturi_asociate, 
			cookie, 
			{}, 
			{setProtectedContent, setError}
		)

	}, [cookie, refresh])  //  array gol, ruleaza o singura data. ruleaza de fiecare data cand este modificat ceva in []; am lasat loading dar nu prea este ok, ar trebui doar cand addAsociereContent === "ok"

	return (
		<LayoutLogged>
      {
        addAsociereContent !== "ok"
        ? <ContAsociatForm error={addAsociereError} loading={loading} handleSubmit={handleSubmit} setCodClient={setCodClient} setIdFactura={setIdFactura} />
        : <ContAsociatFormSuccess />
      }
      {
        Object.keys(protectedContent?.accounts).map((key)=>{

          //  console.log("rand", protectedContent.contracte[key]);
          return(
          <div key={`lca`+key}>
              <ContAsociat info={protectedContent.accounts[key]} setImplicit={setImplicit} delAsociere={delAsociere} />
          </div>
          )
        })
      }
		</LayoutLogged>
	)
}