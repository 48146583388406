import React from "react";
import { Link } from "react-router-dom";

import settings from "../Settings";

import LayoutLoggedUnLogged from "../Components/LayoutLoggedUnLogged";


function DespreNoi() {
  return (
    <LayoutLoggedUnLogged>
    <div className="card">
      <div className="card-header">
        <h4 className="card-title">Despre MY.luxten.com</h4>
      </div>
      <div className="card-body pt-4">
        <p>
          <strong>My.Luxten.com</strong> este serviciul care te ajută să economisești timp și să administrezi cu usurință contractele încheiate cu furnizorul tău de energie electrica.
        </p>
        <p>
          Dacă ai ales să îți faci un cont pe My.luxten.com, vei putea:
        </p>
        <ul className="list-unstyled mb-3">
          <li><span className="bullet-list"></span> gestiona locul/locurile de consum de energie electrică;</li>
          <li><span className="bullet-list"></span> transmite indexul pentru energia electrică în perioada mentionată de către furnizor în factura de energie electrică;</li>
          <li><span className="bullet-list"></span> urmări mai ușor evoluția consumului pentru locul/locurile tale de consum;</li>
          <li><span className="bullet-list"></span> plăti în siguranță facturile pentru locul/locurile tale de consum;</li>
          <li><span className="bullet-list"></span> vizualiza ultimele facturi emise și platile efectuate pentru locul/locurile dumneavoastra de consum. </li>
        </ul>

        <p>
          Pentru a putea beneficia de aceste avantaje, ai nevoie de o adresă de email validă precum si de datele de identificare ale locului de consum (Cod Client si ID factură) prezente pe oricare factură de energie electrică.
        </p>

        <p>
          De asemenea, te rugăm să citești înainte de crearea contului si <Link to={settings.url.frontend.despre_cookie} className="text-primary">Politica de cookie-uri</Link> precum si <Link to={settings.url.frontend.t_c} className="text-primary">Termeni și condiții</Link>.
        </p>
      </div>
    </div>
  </LayoutLoggedUnLogged>
  )
}

export default DespreNoi