import Placeholder from "../Placeholder"

export default function PunctConsum(props) {
  return (
    <div className="card">
      <div className="card-header">
        <h4 className="card-title">Punct de consum</h4>
      </div>
      <div className="card-body">
        <div className="transaction-widget">
          <ul className="list-unstyled">
            <li className="d-flex">
              <div className="flex-grow-1">
                <p>
                  <small>Strada</small>
                </p>
                <p className="wallet-address text-dark">{props.info.strada_consum !== undefined ? props.info.strada_consum : <Placeholder width={30} />}</p>
              </div>
            </li>
            <li className="d-flex">
              <div className="flex-grow-1">
                <p>
                  <small>Nr.</small>
                </p>
                <p className="wallet-address text-dark">{props.info.nr_strada_consum !== undefined ? props.info.nr_strada_consum : <Placeholder width={5} />}</p>
              </div>
            </li>
            <li className="d-flex">
              <div className="flex-grow-1">
                <p>
                  <small>Bloc</small>
                </p>
                <p className="wallet-address text-dark">{props.info.bloc_consum !== undefined ? (props.info.bloc_consum ? props.info.bloc_consum : "N/A") : <Placeholder width={5} />}</p>
              </div>
            </li>
            <li className="d-flex">
              <div className="flex-grow-1">
                <p>
                  <small>Scară</small>
                </p>
                <p className="wallet-address text-dark">{props.info.scara_consum !== undefined ? (props.info.scara_consum ? props.info.scara_consum : "N/A") : <Placeholder width={5} />}</p>
              </div>
            </li>
            <li className="d-flex">
              <div className="flex-grow-1">
                <p>
                  <small>Apartament</small>
                </p>
                <p className="wallet-address text-dark">{props.info.apt_consum !== undefined ? (props.info.apt_consum ? props.info.apt_consum : "N/A") : <Placeholder width={5} />}</p>
              </div>
            </li>
            <li className="d-flex">
              <div className="flex-grow-1">
                <p>
                  <small>Localitate</small>
                </p>
                <p className="wallet-address text-dark">{props.info.oras_consum !== undefined ? props.info.oras_consum : <Placeholder width={10} />}</p>
              </div>
            </li>
            <li className="d-flex">
              <div className="flex-grow-1">
                <p>
                  <small>Judet</small>
                </p>
                <p className="wallet-address text-dark">{props.info.judet_consum !== undefined ? props.info.judet_consum : <Placeholder width={10} />}</p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}