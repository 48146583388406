import React, { useState, useContext } from "react";
import {Link} from "react-router-dom"
import { DropdownButton } from "react-bootstrap";

import CookieConsent from "react-cookie-consent";

//  import Navbar from 'react-bootstrap/Navbar';
//  import Nav from 'react-bootstrap/Nav';

import { useCookies } from 'react-cookie';

import settings from "../Settings";
import { fetchDataLogout } from "../Requests";

import {ContextLogin} from "../Contexts/Login";

export default function Header() {
  const [contextLogged, setContextLogged] = useContext(ContextLogin);
  const [, setError] = useState('');
  const [cookie,setCookie] = useCookies([settings.auth.cookie_token]);
  

  const logout = () => {
    fetchDataLogout(cookie, setCookie, setContextLogged, setError);
  }

  //  console.log(contextLogged);
  return (
    <>
      <div className="header dashboard">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-12">
              <nav className="navbar navbar-expand-lg navbar-light px-0 justify-content-between">
                {/* <Link className="navbar-brand" to={'/'}><img src={require('./../../images/logo.png')} alt="" /></Link> */}

                
                <div className="header-search d-flex align-items-center">
                  <Link className="brand-logo me-3" href="index.html">
                    <img src={require('./../images/logo.png')} alt="" width="50" />
                  </Link>
                  {/*
                  <form>
                      <div className="input-group">
                          <input type="text" className="form-control" placeholder="Search" />
                          <div className="input-group-append">
                              <span className="input-group-text" id="basic-addon2"><i className="fa fa-search"></i></span>
                          </div>
                      </div>
                  </form>
                  */}
                </div>

                <div className="dashboard_log my-2">
                  <div className="d-flex align-items-center">
                    <DropdownButton
                        title={contextLogged.ok.username}
                        className="profile_log"
                    >
                      {/*
                      <Link to={'/accounts'} className="dropdown-item">
                          <i className="la la-user"></i> Informatii cont
                      </Link>
                      <Link to={'/history'} className="dropdown-item">
                          <i className="la la-book"></i> Informatii utile
                      </Link>
                      */}

                      {/*
                      <li>
                        <Link to={settings.url.frontend.conturi_asociate}>
                          <span><i className="mdi mdi-account-multiple-plus"></i></span>
                          <span className="nav-text">Conturi asociate</span>
                        </Link>
                      </li>
                      */}
                      <Link to={settings.url.frontend.conturi_asociate} className="dropdown-item">
                          <i className="la la-user-plus"></i> Conturi asociate
                      </Link>

                      <Link to={settings.url.frontend.change_password} className="dropdown-item">
                          <i className="la la-lock"></i> Schimbare parolă
                      </Link>
                      <Link onClick={logout} className="dropdown-item logout">
                          <i className="la la-sign-out"></i> Logout
                      </Link>
                    </DropdownButton>
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>

      <CookieConsent
        location="bottom"
        buttonText="Sunt de acord!"
        cookieName="cookie_consent"
        style={{ background: "#2B373B" }}
        expires={2}
      >
        Cookie-urile ne permit să vă oferim online serviciile noastre. Pentru a vă bucura de toate facilitățile website-ului este necesar să le acceptați.{" "}
        <Link to={settings.url.frontend.despre_cookie} className="secondary-text small">Click aici pentru mai multe detalii</Link>
      </CookieConsent>
    </>
  )
  /*
  return (
    <>
      <h2>My.luxten.com</h2>
      <h3>Bine ai venit, {contextLogged.ok.username}</h3>
      <button className="btn btn-primary" onClick={logout}>
        Logout
      </button>
      <hr />
      <Navbar bg="light" expand="lg">
          <Navbar.Brand href="/">Contul meu</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link href="/">Home</Nav.Link>
              <Nav.Link href="/istoric_facturi">Istoric facturi</Nav.Link>
              <Nav.Link href="#contact">Contact</Nav.Link>
            </Nav>
          </Navbar.Collapse>
      </Navbar>
    </>
  )
  */
}
