import React, {useState	} from "react";

import { fetchData } from "../Requests";
import settings from "../Settings";

import ResendEmailForm from "../Components/ResendEmail/ResendEmailForm";
import ResendEmailFormSuccess from "../Components/ResendEmail/ResendEmailFormSuccess";

function ResendEmail() {
  const [error, setError] = useState([]);
	const [loading, setLoading] = useState(0);
  const [responseOk, setResponseOk] = useState([]);

  const [username, setUsername] = useState('');


  const handleSubmit = (event) => {
    event.preventDefault();
		setLoading(1);	//	se marcheaza ca se incarca

    fetchData(settings.url.auth.resend_email,
			{username}, 
			{setError, setResponseOk, setLoading}
		)
  };

	if(responseOk !== "ok") {
		return (
			<ResendEmailForm error={error} loading={loading} handleSubmit={handleSubmit} setUsername={setUsername} />
		)
	} else {
		return (
			<ResendEmailFormSuccess />
		)
	}

}

export default ResendEmail