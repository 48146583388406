import React from "react";

export default function ChangePasswordFormSuccess() {
  return (
    <div className="card">
      <div className="card-header">
          <h4 className="card-title">Parola a fost schimbată cu succes!</h4>
      </div>
      <div className="card-body pt-0">
        <p className="mt-3">Pentru a vă putea autentifica pe viitor, va trebui sa folosiți noua parolă setată.</p>
      </div>
    </div>
  );
}