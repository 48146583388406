import React from "react"

import HeaderUnLogged from "../HeaderUnLogged"

import { Submit } from "../FormInputs"

export default function ForgotPasswordForm(props) {
  
  return (
    <>
      <HeaderUnLogged />
      <div className="authincation section-padding">
        <div className="container h-100">
          <div className="row justify-content-center h-100 align-items-center">
            <div className="col-xl-5 col-md-6 mt-5">
              <div className="auth-form card">
                <div className="card-header justify-content-center">
                  <h4 className="card-title">
                    Resetare parolă
                  </h4>
                </div>
                <div className="card-body">
                  <form
                    method="post"
                    className="signup_validate"
                    onSubmit={props.handleSubmit}
                  >
                    <div className="mb-3">
                      <label>Introduceți adresa de email folosită la înregistrare</label>
                      {props.error !== null ? <div className="mb-3 text-danger">{props.error.username}</div> : null}
                      <input
                        type="text"
                        placeholder="Email"
                        name="username"
                        className={props.error?.username !== undefined ? "border-danger form-control" : "form-control"}
                        onChange={(event) => props.setUsername(event.target.value)}
                      />
                    </div>
                    <div className="text-center mt-4">
                      <Submit loading={props.loading} title="Resetare parolă" />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>     
  )
}