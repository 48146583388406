import settings from "./Settings";

/**
 * delogare
 * @param {*} cookie 
 * @param {*} setCookie 
 * @param {*} setContextLogged 
 * @param {*} setError 
 */
export function fetchDataLogout(cookie, setCookie, setContextLogged, setError) {
  async function fetchData() {
    try {
      let options = {};
      // daca gasesc token valid, il trimit catre server
      if(cookie[settings.auth.cookie_token]) {
        options = {
          method: "GET",
          headers: {
            'Authorization': `Bearer ${cookie[settings.auth.cookie_token]}`,
            'X-Ref': settings.auth.ref
          }
        };
      }
      const response = await fetch(settings.url.auth.logout, options);
      const json = await response.json();
      if(json.ok !== undefined) {
        // sterg token, oricum este inutil
        //  removeCookie(settings.auth.cookie_token);  // da avertizarea Cookie-ul „_ut” nu are o valoare corespunzătoare pentru atributul „SameSite”
        setCookie(settings.auth.cookie_token, '', settings.auth.cookie_options);
        //  marchez ca delogat
        setContextLogged({err: {}});
      } else if(json.err !== undefined) {
        setError(json.err);
      } else {
        setError('Undefined error');
      }
    } catch (error) {
      setError(error.toString());
    }
  }
  fetchData();
}

/**
 * aduce datele de autentificare
 * 
 * @param {*} setCookie 
 * @param {*} postParams 
 * @param {*} setStates 
 */
  export function fetchDataLogin(setCookie, postParams, setStates) {
  async function fetchData() {
    try {
      const formData = new FormData();
      formData.append("username", postParams.username);
      formData.append("password", postParams.password);
      formData.append("remember_me", postParams.isChecked ? 1 : 0);
      formData.append("ref", settings.auth.ref);
      formData.append("isVerified", postParams.isVerified);
      const response = await fetch(settings.url.auth.login, {
        method: "POST",
        body: formData,
      });
      const json = await response.json();
      if(json.ok !== undefined) {
        // setez tokenul cu credentiale
        // daca de expirare nu este in setari, se primeste tot de la server
        const now = new Date(); // momentul curent
        const expires = new Date(now.getTime() + json.ok.exp * 1000); 
        
        setCookie(
          settings.auth.cookie_token, 
          json.ok.token, 
          //  settings.auth.cookie_options
          {
            path: settings.auth.cookie_options.path,
            secure: settings.auth.cookie_options.secure,
            httpOnly: settings.auth.cookie_options.httpOnly,
            sameSite: settings.auth.cookie_options.sameSite,
            expires
          }
        );
        setStates.setContextLogged(json);
      } else if(json.err !== undefined) {
        setStates.setError(json.err); //  las asa, deoarece mai am un parametru
      } else {
        setStates.setError('Undefined error');
      }

    } catch (error) {
      setStates.setError(error.toString());
    }

    setStates.setLoading(0);
  }
  fetchData();
}

export function fetchDataUserGetInfo(setContextLogged, setError, cookie) {
  async function fetchData() {
    try {
      let options = {};
      // daca gasesc token valid, il trimit catre server
      if(cookie[settings.auth.cookie_token]) {
        options = {
          method: "GET",
          headers: {
            'Authorization': `Bearer ${cookie[settings.auth.cookie_token]}`,
            'X-Ref': settings.auth.ref
          }
        };
      }
      const response = await fetch(settings.url.auth.get_info, options);
      const json = await response.json();
      setContextLogged(json);
    } catch (error) {
      setError(error.toString());
    //  } finally {
    //  setLoading(false);
    }
  }
  fetchData();
}

/**
 * 
 * @param {object} postParams lista de parametri POST care se vor trimite
 * @param {object} setStates lista de functii care seteaza principalele state-uri: error, responseOk, loading
 */
export function fetchData(url, postParams, setStates) {
  async function fetchData() {
    let firstKey = settings.miscellaneous.firstKey;
    try {
      const formData = new FormData();
      Object.keys(postParams).map((key) => {
        formData.append(key, postParams[key]);
        firstKey = ((firstKey === settings.miscellaneous.firstKey) ? key : firstKey);
        return null;
      });
      const response = await fetch(url, {
        method: "POST",
        body: formData,
      });
      const json = await response.json();
      
      if(json.ok !== undefined) {
        setStates.setResponseOk(json.ok);
        setStates.setError([]);
      } else if(json.err !== undefined) {
        setStates.setError(json.err);
      } else {
        //  console.log('raspuns inconsecvent');
        setStates.setError({[firstKey]: settings.messages.server_unknown_error});
      }
    } catch (error) {
      //  console.log(firstKey, error.toString(), settings.messages.server_error);
      setStates.setError({[firstKey]: settings.messages.server_error});
    }

    setStates.setLoading(0);
  }
  fetchData();
}

/**
 * requesturi pentru sectiuni autentificate; aici in aceasta faza nu am nevoie de loading deoarece am placeholdere care asteapta sa fie populate
 * @param {*} cookie 
 * @param {*} setProtectedContent 
 * @param {*} setError 
 */
export function fetchProtectedData(url, cookie, postParams, setStates) {
  // nu fac request daca nu exista cookie setat!
  if(!cookie[settings.auth.cookie_token]) {
    return;
  }

  async function fetchData() {
    let firstKey = settings.miscellaneous.firstKey;
    try {
      const formData = new FormData();
      Object.keys(postParams).map((key) => {
        formData.append(key, postParams[key]);
        firstKey = ((firstKey === settings.miscellaneous.firstKey) ? key : firstKey);
        return null;
      });

      let options = {};
      options = {
        method: "POST",
        headers: {
          'Authorization': `Bearer ${cookie[settings.auth.cookie_token]}`,
          'X-Ref': settings.auth.ref
        },
        body: formData
      };
      const response = await fetch(url, options);
      const json = await response.json();
      
      /*
      console.log(json, postParams);
      if(json.ok) {
        setStates.setProtectedContent(json.ok);
      }
      */
      if(json.ok !== undefined) {
        setStates.setProtectedContent(json.ok);
        //  doar daca este definit, nu mereu este definit
        if(setStates.setError !== undefined) {
          setStates.setError([]);
        }
        // in caz ca se doreste sa se stie cand s-a primit raspuns de succes; loadingul ruleaza la fiecare submitere deci nu prea este util
        // se seteaza doar daca este definit, nu mereu este definit; in setStates ar trebui sa fie doar functii; ca sa nu mai introduc alt parametru, am pus refresh (valoarea modificata de setRefresh) tot la functii
        if(setStates.setRefresh !== undefined) {
          setStates.setRefresh(setStates.refresh + 1);
        }
      } else if(json.err !== undefined) {
        //  doar daca este definit, nu mereu este definit
        if(setStates.setError !== undefined) {
          setStates.setError(json.err);
        }
      } else {
        //  doar daca este definit, nu mereu este definit
        if(setStates.setError !== undefined) {
          setStates.setError({[firstKey]: settings.messages.server_unknown_error});
        }
      }
    } catch (error) {
      //  doar daca este definit, nu mereu este definit
      if(setStates.setError !== undefined) {
        setStates.setError(error.toString());
      }
    }
    //  doar daca este definit, nu mereu este definit
    if(setStates.setLoading !== undefined) {
      setStates.setLoading(0);
    }
  }

  fetchData();
}

export function fetchBlobProtectedData(url, cookie, denumireFisierDownload, postParams, setStates) {
  // nu fac request daca nu exista cookie setat!
  if(!cookie[settings.auth.cookie_token]) {
    return;
  }

  async function fetchData() {
    try {
      let options = {};
      const formData = new FormData();
      Object.keys(postParams).map((key) => {
        formData.append(key, postParams[key]);
        return null;
      });

      options = {
        method: "POST",
        body: formData,
        headers: {
          'Authorization': `Bearer ${cookie[settings.auth.cookie_token]}`,
          'X-Ref': settings.auth.ref
        }
      };
      const response = await fetch(url, options);
      const blob = await response.blob();
  
      // crearea URL-ului de obiect temporar
      let urlDownload = URL.createObjectURL(blob);
  
      // crearea elementului <a> și setarea atributelor sale
      const link = document.createElement('a');
      link.href = urlDownload;
      link.download = denumireFisierDownload; 
      document.body.appendChild(link);
  
      // apăsați elementul pentru a descărca fișierul
      link.click();
  
      // elimina elementul din DOM
      document.body.removeChild(link);
  
      // eliberarea resurselor URL
      URL.revokeObjectURL(urlDownload);
  
    } catch (error) {
      //console.error(error.toString());
    }

    //  doar daca este definit, nu mereu este definit
    if(setStates.setLoading !== undefined) {
      setStates.setLoading(0);
    }
  }
  fetchData();
}