import {BrowserRouter, Routes, Route} from "react-router-dom";

import './css/style.css';
import './css/custom.css';


import ContulMeu from './Pages/ContulMeu';
import FacturileMele from './Pages/FacturileMele';
import PlatileMele from "./Pages/PlatileMele";
import TransmitereIndex from "./Pages/TransmitereIndex";
import ConturiAsociate from "./Pages/ConturiAsociate";
import IstoricIndecsi from "./Pages/IstoricIndecsi";
import Contracte from "./Pages/Contracte";
import DespreNoi from "./Pages/DespreNoi";
import DespreCookie from "./Pages/DespreCookie";
import TermeniConditii from "./Pages/TermeniConditii";
import ForgotPassword from "./Pages/ForgotPassword";
import SignUp from "./Pages/SignUp";
import ResendEmail from "./Pages/ResendEmail";
import ChangePassword from "./Pages/ChangePassword";
import ActivateAccount from "./Pages/ActivateAccount";
import ResetPassword from "./Pages/ResetPassword";
import RaspunsPlata from "./Pages/RaspunsPlata";

import Page404 from './Pages/Page404';

// (**) trebuie sa dispara pe productie
//  import Test from './Pages/Test';

import settings from "./Settings";
//  import {getLoginInfo} from './Requests/Login';

export default function App() {
  
  
  //console.log('incarc prima data. Pare sa ca se incarca dublu din cauza <React.StrictMode>');
  
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<ContulMeu />}></Route>
        {/*<Route exact path="/test54321" element={<Test />}></Route>*/}

        <Route exact path={settings.url.frontend.facturile_mele} element={<FacturileMele />}></Route>
        <Route exact path={settings.url.frontend.platile_mele} element={<PlatileMele />}></Route>
        <Route exact path={settings.url.frontend.transmitere_index} element={<TransmitereIndex />}></Route>
        <Route exact path={settings.url.frontend.contracte} element={<Contracte />}></Route>
        <Route exact path={settings.url.frontend.conturi_asociate} element={<ConturiAsociate />}></Route>
        <Route exact path={settings.url.frontend.istoric_indecsi} element={<IstoricIndecsi />}></Route>

        <Route exact path={settings.url.frontend.despre_noi} element={<DespreNoi />}></Route>
        <Route exact path={settings.url.frontend.despre_cookie} element={<DespreCookie />}></Route>
        <Route exact path={settings.url.frontend.t_c} element={<TermeniConditii />}></Route>
        <Route exact path={settings.url.frontend.forgot_password} element={<ForgotPassword />}></Route>
        <Route exact path={settings.url.frontend.sign_up} element={<SignUp />}></Route>
        <Route exact path={settings.url.frontend.resend_email} element={<ResendEmail />}></Route>
        <Route exact path={settings.url.frontend.change_password} element={<ChangePassword />}></Route>
        <Route exact path={`${settings.url.frontend.raspuns_plata}/:orderHash`} element={<RaspunsPlata />}></Route>
        <Route exact path={`${settings.url.frontend.activate_account}/:jwt`} element={<ActivateAccount />}></Route>
        <Route exact path={`${settings.url.frontend.reset_password}/:jwt`} element={<ResetPassword />}></Route>
        <Route  path="*" element={<Page404 />}></Route>
      </Routes>
    </BrowserRouter>
  );
  
}

/*
import React, { useState, useEffect } from "react";
import {BrowserRouter, Routes, Route} from "react-router-dom";
import { ContextLogin } from "./Contexts/Login";

import ContulMeu from './Pages/ContulMeu/ContulMeu';
import IstoricFacturi from './Pages/IstoricFacturi/IstoricFacturi';
import Page404 from './Pages/Page404/Page404';

//  import {getLoginInfo} from './Requests/Login';

export default function App() {
  const [context, setContext] = useState({
    status: 0,
    username: null
  });
  
  useEffect(() => {
    //  getLoginInfo();

    setTimeout(()=>{
      setContext({status: 10});
    }, 3000);
    
    console.log('acilea', context);
  }, [])  //  array gol, deci ruleaza o singura data!

  console.log('incarc prima data');
  
  return (
    <ContextLogin.Provider value={[context, setContext]}>
      <BrowserRouter>
        <Routes>
        <Route exact path="/" element={<ContulMeu />}></Route>
        <Route exact path="/istoric_facturi" element={<IstoricFacturi />}></Route>
          <Route  path="*" element={<Page404 />}></Route>
        </Routes>
      </BrowserRouter>
    </ContextLogin.Provider>
  );
  
}
*/


/*
import React from 'react';
import {BrowserRouter, Routes, Route} from "react-router-dom";

//  import SampleContextProvider from './Contexts/SampleContext';
//export const UserContext = React.createContext();

import ContulMeu from './Pages/ContulMeu/ContulMeu';
import Page404 from './Pages/Page404/Page404';

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.state.loginInfo = 'hau';
  }

  componentDidMount() {
    // setTimeout(function(){
    //   this.setState({
    //     'loginInfo': 'bau'
    //   });
    // }, 3000);
    setTimeout(() => 
      this.setState({
        'loginInfo': 'bau'
      }
    ), 3000);
    
  }

  render() {
    console.log("App.jsx", this.props, this.state);
      
    return (
      <BrowserRouter>
        <Routes>
        
        
        
          <Route exact path="/" element={<ContulMeu loginInfo={this.state.loginInfo} />}></Route>
    
    
    
          <Route  path="*" element={<Page404 />}></Route>
          
          

        </Routes>
        
      </BrowserRouter>
    );
  }

}

export default App;

//  https://dev.to/efearas/how-to-usecontext-and-set-value-of-context-in-child-components-in-3-steps-3j9h
//  https://codesandbox.io/s/usecontext-forked-4jou3s?file=/src/ComponentA.js

*/