import React from 'react'
import { Link } from 'react-router-dom'

export default function Error() {
  return (
    <div className="intro section-padding position-relative">
      <div className="container">
        <div className="row align-items-center justify-content-between">
          <div className="col-xl-6 col-md-6">
            <div className="intro-content mb-5">
              <h2 className="text-danger">Eroare!</h2>
              <p>Ne pare rău dar pagina pe care încercați să o accesați nu este disponibilă!</p>
              <p>Vă rugăm să încercați din nou în câteva momente sau să vă întoarceti la pagina principală.</p>
              <Link to="/" className="btn btn-primary" data-scroll-nav="1">Home</Link>
            </div>
          </div>
          <div className="col-xl-6 col-md-6 py-md-5">
            <div className="intro-demo_img intro-img">
              <img src={require('./../images/error.jpg')} alt="" className="img-fluid" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
