import React from 'react';
import { Link } from 'react-router-dom';
import Icon from '@mdi/react';
import { mdiCalendarText, mdiViewDashboard, mdiTable, mdiInformationOutline, mdiCreditCard, mdiChartLine} from '@mdi/js';

import settings from '../Settings';

//	import { Container, Row, Col } from "react-bootstrap";


function Sidebar() {
	

	
	return (
		<>
			<div className="sidebar">
				<Link className="brand-logo" to="/">
					<img src={require('../images/logo.png')} alt="" className="w-50" />
					<span>{settings.website.title}</span>
				</Link>
				<div className="menu mb-5">
					<ul>
						<li>
							<Link to="/">
								<Icon path={mdiViewDashboard} size={1} />
								<span className="nav-text">Home</span>
							</Link>
						</li>
						<li>
							<Link to={settings.url.frontend.facturile_mele}>
								<Icon path={mdiTable} size={1} />
								<span className="nav-text">Facturile mele</span>
							</Link>
						</li>
						<li>
							<Link to={settings.url.frontend.contracte}>
								<Icon path={mdiInformationOutline} size={1} />
								<span><i className="mdi mdi-information-outline"></i></span>
								<span className="nav-text">Contracte</span>
							</Link>
						</li>
						<li>
							<Link to={settings.url.frontend.platile_mele}>
								<Icon path={mdiCreditCard} size={1} />
								<span><i className="mdi mdi-credit-card"></i></span>
								<span className="nav-text">Platile mele</span>
							</Link>
						</li>
						<li>
							<Link to={settings.url.frontend.transmitere_index}>
								<Icon path={mdiCalendarText} size={1} />
								<span className="nav-text">Transmitere index</span>
							</Link>
						</li>
						
						<li><
							Link to={settings.url.frontend.istoric_indecsi}>
								<Icon path={mdiChartLine} size={1} />
								<span className="nav-text">Istoric indecsi</span>
							</Link>
						</li>
					</ul>
				</div>

				<div className="sidebar-footer">
					<ul>
						<li><Link to={settings.url.frontend.despre_noi}><span className="nav-text">Despre {settings.website.title}</span></Link></li>
						<li><Link to={settings.url.frontend.t_c}><span className="nav-text">Termeni si conditii</span></Link></li>
						<li><Link to={settings.url.frontend.despre_cookie}><span className="nav-text">Politica de cookie-uri</span></Link></li>						
					</ul>
					<div className="copy_right mt-4"> &copy; {new Date().getFullYear()} {settings.website.companyName}</div>
				</div>
			</div>
		</>
	)
	
}

export default Sidebar;