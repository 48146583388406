import React, {useEffect} from "react"
import { useCookies } from "react-cookie"
import { useState } from "react"

import settings from "../../Settings"
import { fetchProtectedData } from "../../Requests"
import TransmitereIndexMesaj2 from "./TransmitereIndexMesaj2"
import TransmitereIndexMesaj3 from "./TransmitereIndexMesaj3"
import TransmitereIndexForm from "./TransmitereIndexForm"
import TransmitereIndexFormSuccess from "./TransmitereIndexFormSuccess"
import Placeholder from "../Placeholder"


export default function TransmitereIndexContract(props) {
  // pentru autentificare
  const [cookie] = useCookies([settings.auth.cookie_token]);

  // pentru lista de contracte
  const [error, setError] = useState(null);
  const [protectedContent, setProtectedContent] = useState({})

  const [loading, setLoading] = useState(0);
  const [indexAutocitit, setIndexAutocitit] = useState('');

  const handleSubmit = (event) => {
    // in situatia in care se poate trimite indexul autocitit, aceasta este actiunea cand se apasa butonul de submit

    event.preventDefault();
		setLoading(1);	//	se marcheaza ca se incarca

    fetchProtectedData(
      settings.url.contul_meu.add_index_autocitit,
      cookie, 
      {
        idContract: props.idContract,
        indexAutocitit, setIndexAutocitit
      },
      {setProtectedContent, setError, setLoading}
    )
  };  


  useEffect(() => {
    // prima data rulez ca sa aflu care este starea, daca se poate sau nu se poate submite indexul;
    if(props.idContract > 0) {
      //  console.log("aduc info", props.idContract);
      fetchProtectedData(
        settings.url.contul_meu.add_index_autocitit, 
        cookie, 
        {
          idContract: props.idContract,
          firstTime: 1
        }, 
        {
          setProtectedContent, 
          setError,
        }
      )
    }
    
	}, [cookie, props.idContract]) // foarte ciudat, nu mai ruleaza daca se actualizeaza propsul.

  switch(true) {
    case error?.__status__ === 1:
      return (
        <p>Nu a fost gasit id contract!</p>
      )
    case error?.__status__ === 2:
      return <TransmitereIndexMesaj2 ziMin={error.ziMin} ziMax={error.ziMax} />
    case error?.__status__ === 3:
      return <TransmitereIndexMesaj3 />

    case protectedContent === 'ok':
      return <TransmitereIndexFormSuccess />
    case error !== null:  // doar prima data este null, dupa care are valori setate; deci doar prima data nu se afiseaza si se lasa placeholderul
      return (<TransmitereIndexForm error={error} loading={loading} handleSubmit={handleSubmit} indexAutocitit={indexAutocitit} setIndexAutocitit={setIndexAutocitit}  />)
    default: 
      return <Placeholder width={25} />
  }
}

