import React, {useState	} from "react";
import { useParams } from "react-router-dom";

import { fetchData } from "../Requests";
import settings from "../Settings";

import ResetPasswordForm from "../Components/ResetPassword/ResetPasswordForm";
import ResetPasswordFormSuccess from "../Components/ResetPassword/ResetPasswordFormSuccess";

function SignUp() {
  const { jwt } = useParams();

  const [error, setError] = useState([]);
	const [loading, setLoading] = useState(0);
  const [responseOk, setResponseOk] = useState([]);

  const [password, setPassword] = useState('');
  const [checkPassword, setCheckPassword] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();
		setLoading(1);	//	se marcheaza ca se incarca

    fetchData(settings.url.auth.reset_password,
			{
        password, //  primul post este un camp vizibil, deoarece aici se va afisa un mesaj de eroare implicit
        checkPassword,
        ui: jwt,
    	}, 
			{setError, setResponseOk, setLoading}
		)
  };

	if(responseOk !== "ok") {
		return (
			<ResetPasswordForm error={error} loading={loading} handleSubmit={handleSubmit} setPassword={setPassword} setCheckPassword={setCheckPassword} />
		)
	} else {
		return (
			<ResetPasswordFormSuccess />
		)
	}

}

export default SignUp