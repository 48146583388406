import moment from "moment";

import Placeholder from "../Placeholder"

export default function InformatiiClient(props) {
  return (
  <div className="card">
    <div className="card-header">
        <h4 className="card-title">Informații client</h4>
    </div>
    <div className="card-body">
      <div className="transaction-widget">
        <div className="row">
            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 mb-4">
              <p>
                <small>Denumire client</small>
              </p>
              <p className="wallet-address text-dark"> {props.info.denumire_companie !== undefined ? props.info.denumire_companie : <Placeholder width={30} /> } </p>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 mb-4">
              <p>
                <small>Nr./data contract</small>
              </p>
              <p className="wallet-address text-dark">
                {props.info.nr !== undefined ? props.info.nr : <Placeholder width={5} /> }
                 {" "} din {" "} 
                 {props.info.data !== undefined ? moment(props.info.data).format("DD/MM/YYYY") : <Placeholder width={10} /> }
                </p>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 mb-4">
              <p>
                <small>Data expirare</small>
              </p>
              <p className="wallet-address text-dark">{props.info.fara_data_expirare !== undefined ? (props.info.fara_data_expirare === '1' ? "NEDETERMINAT" : moment(props.info.data_expirare).format("DD/MM/YYYY") ) : <Placeholder width={10} />}</p>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 mb-4">
              <p>
                <small>CIF</small>
              </p>
              <p className="wallet-address text-dark">{props.info.cui_companie !== undefined ? props.info.cui_companie : <Placeholder width={5} />}</p>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 mb-4">
              <p>
                <small>Tip beneficiar</small>
              </p>
              <p className="wallet-address text-dark">{props.info.titlu_tip_beneficiar !== undefined ? props.info.titlu_tip_beneficiar : <Placeholder width={5} />}</p>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 mb-4">
              <p>
                <small>Categorie contract</small>
              </p>
              <p className="wallet-address text-dark">{props.info.titlu_categorie !== undefined ? props.info.titlu_categorie : <Placeholder width={10} />}</p>
            </div>
        </div>
      </div>
    </div>
  </div>

  )
}