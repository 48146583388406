import React, { useState, useEffect } from "react";
import { useCookies } from 'react-cookie';

import settings from "../Settings";
import { fetchProtectedData } from "../Requests";

import LayoutLogged from "../Components/LayoutLogged"

import InformatiiClient from "../Components/Contracte/InformatiiClient";
import InformatiiContract from '../Components/Contracte/InformatiiContract';
import PunctConsum from '../Components/IstoricIndecsi/PunctConsum';


export default function Contracte() {

  const [, setError] = useState(null);
	const [cookie] = useCookies([settings.auth.cookie_token]);
  const [protectedContent, setProtectedContent] = useState({
    contracte: [
      {}
    ]
  });

	useEffect(() => {
    fetchProtectedData(
			settings.url.contul_meu.contracte, 
			cookie, 
			{}, 
			{setProtectedContent, setError}
		)

	}, [cookie])  //  array gol, ruleaza o singura data. ruleaza de fiecare data cand este modificat ceva in []

  return (
    <LayoutLogged>
      <div className="container">
      {
        Object.keys(protectedContent?.contracte).map((key)=>{

          //  console.log("rand", protectedContent.contracte[key]);
          return(
            <div key={key}  className="row">
              <div className="col-xl-12">
                <InformatiiClient info={protectedContent.contracte[key]} />
                <InformatiiContract info={protectedContent.contracte[key]} />
                <PunctConsum info={protectedContent.contracte[key]} />
              </div>
            </div>
          )
        })
      }
    </div>
    </LayoutLogged>
  )

}