import { Link } from "react-router-dom";

import settings from "../../Settings";
import Placeholder from "../Placeholder";
export default function SoldContabil(props) {
  return (
    <div className="widget-card gradient-background">
      <div className="widget-title">
        <h5>Rest de plată</h5>
      </div>
      <div className="widget-info">
          <h1>{props.info.sold_contabil_formatat !== undefined ? props.info.sold_contabil_formatat : <Placeholder width={10} />}</h1>
          <p><Link to={settings.url.frontend.facturile_mele} className="btn btn-light p-2">Mai multe detalii</Link></p>
      </div>
      {
        props.info.rest_de_asociat !== undefined && props.info.rest_de_asociat !== 0
        ?
        (
          <>
            <p className="m-0">
              <small>Din care: </small>
            </p>
            <p className="m-0">
              <small>Total facturi neachitate: {props.info.facturi_neachitate_formatat !== undefined ? props.info.facturi_neachitate_formatat : <Placeholder width={10} />} </small>
            </p>
            <p className="m-0">
              <small>Total plăți avans sau neasociate: {props.info.rest_de_asociat_formatat !== undefined ? props.info.rest_de_asociat_formatat : <Placeholder width={10} />}</small>
            </p>
          </>
        )
        : ""
      }

    </div>
  )
}