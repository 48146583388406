import React from "react";
import {Link} from "react-router-dom";

import HeaderUnLogged from "../HeaderUnLogged";

export default function SignUpFormSuccess() {
  return (
    <>
      <HeaderUnLogged />
      <div className="authincation section-padding">
        <div className="container h-100">
          <div className="row justify-content-center h-100 align-items-center">
            <div className="col-xl-5 col-md-6 mt-5">
              {/*
              <div className="mini-logo text-center my-5">
                <Link to={"/"}><img src={require("./../images/logo.png")} alt="" /></Link>
              </div>
              */}
              <div className="auth-form card">
                  <div className="card-header justify-content-center">
                    <h4 className="card-title">Creare cont</h4>
                  </div>
                  <div className="card-body">
                    <p>Contul a fost adaugat cu succes!</p>
                    <p>Înainte de a putea folosi contul, vă rugăm să folosiți link-ul de activare trimis la adresa de email cu care v-ați înregistrat.</p>

                    <div className="new-account mt-3">
                      <p>
                        <Link
                          className="text-primary"
                          to={"/"}
                        >
                          Întoarcere la pagina de autentificare
                        </Link>
                      </p>
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}